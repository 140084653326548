import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap } from 'rxjs/operators';
import { DeviceMapVO, DeviceMappingService } from 'src/app/services/device-mapping/device-mapping.service';

@Component({
  selector: 'app-search-device',
  templateUrl: './search-device.component.html',
  styleUrls: ['./search-device.component.scss'],
})
export class SearchDeviceComponent implements OnInit {
  @Output() deviceSelected = new EventEmitter<DeviceMapVO>();
  @Output() noSelectedOption = new EventEmitter<boolean>();
  @Input() searchInput: FormControl = new FormControl();
  @Input() manufacturer: string;
  @Input() searchModel: any = { appearance: 'legacy', showLabel: false };

  devicesUnmapped$: any;
  constructor(private deviceUnmappedService: DeviceMappingService) {}

  ngOnInit(): void {
    this.getUnmappedDevices();
  }

  getUnmappedDevices(): void {
    this.devicesUnmapped$ = this.searchInput.valueChanges.pipe(
      startWith(''),
      filter((text) => !!text && text.length > 2),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value: string) => this.deviceUnmappedService.getUnmappedDevicesByImei(value))
    );
  }

  emitSelectedDevice(device: DeviceMapVO) {
    this.deviceSelected.emit(device);
  }

  displayDevice(device: any): string {
    return device?.device?.imei ? device.device?.imei : '';
  }
}
