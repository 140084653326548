import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { PatientListService } from 'src/app/clinical/dashboards/patient-list/patient-list.service';
import { Patient } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-patient-autocomplete',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.scss'],
})
export class SearchPatientComponent implements OnInit, OnChanges {
  @Output() patientSelected = new EventEmitter<Patient>();
  @Output() noSelectedOption = new EventEmitter<boolean>();
  @Input() searchInput: FormControl = new FormControl();
  @Input() searchModel: any = { appearance: 'legacy', showLabel: false };
  filteredOptions$: Observable<any[]>;
  searchMax = 15;
  constructor(private patientService: PatientListService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchInput && !changes.searchInput?.firstChange) {
      this.setFilteredOptionToSearch();
    }
  }

  ngOnInit(): void {
    this.setFilteredOptionToSearch();
  }
  emitSelectedPatient(patient: Patient) {
    this.patientSelected.emit(patient);
  }

  getOptionText(option) {
    return option ? `${option.firstName} ${option.lastName}` : '';
  }

  handleImageError(event: any): void {
    event.target.src = environment.defaultPhotoUrl ?? '';
  }

  protected setFilteredOptionToSearch(): void {
    this.filteredOptions$ = this.searchInput.valueChanges.pipe(
      startWith(''),
      filter((text) => !!text && text.length > 0),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((value: string) =>
        this.patientService.filterPatientsFromElasticSearch(
          this.searchMax,
          {
            firstName: value,
            lastName: value,
            email: value,
            sms_contact: value,
            phone: value,
            primaryContact: value,
            emr_data: {
              emr_guid: value,
              mrn: value,
            },
            client_responsible: value,
            provider: {
              firstName: value,
              lastName: value,
            },
          },
          { page: 1 }
        )
      ),
      map((result: any) => result.items)
    );
  }
}
