// import { DeviceMapVO } from 'src/app/services/device-mapping/device-mapping.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeAll, take } from 'rxjs/operators';
import { FirestoreService } from 'src/app/services/firestore.service';
import { environment } from 'src/environments/environment';
import { NewAuthService } from '../auth/new-auth-service.service';
import { DataService } from '../data.service';
import { DeviceVO, PatientVO } from '../device-ordering/value-objects/order.vo';
import { PatientService } from '../patient.service';
import { UtilsService } from '../utils.service';
import { InitialDataModel } from './../models/initial-data-model.service';

export interface DeviceMapVO {
  id: string;
  owner: string;
  device: DeviceVO;
  patient: PatientVO;
  createdAt: number;
  updatedAt: number;
}

@Injectable({
  providedIn: 'root',
})
export class DeviceMappingService {
  maxClientAccounts = 15;
  constructor(
    private http: HttpClient,
    private fsService: FirestoreService,
    private ps: PatientService,
    private ds: DataService,
    private auth: NewAuthService,
    private initialModelService: InitialDataModel,
    private utilsService: UtilsService
  ) {}

  getUnmappedDevices(uid?: string): Observable<any[]> {
    const availableClients = this.initialModelService.clientAccounts;
    if (this.auth.user.roles.isAdmin || this.auth.user.roles.isWelbyClinical) {
      return this.fsService.col$('hardware_not_mapped');
    } else if (availableClients.length <= this.maxClientAccounts) {
      return this.fsService.col$('hardware_not_mapped', (ref) => ref.where('client_id', 'in', availableClients));
    } else {
      const chunks$ = this.utilsService.getChunksObsWhenArrayLengthIsGreaterThanMaxValue(availableClients);

      return chunks$.pipe(
        map((chunk) => this.fsService.col$('hardware_not_mapped', (ref) => ref.where('client_id', 'in', chunk))),
        mergeAll()
      );
    }
  }
  getUnmappedDevicesByImei(imei: string, manufacturer?: string): Observable<any[]> {
    const availableClients = this.initialModelService.clientAccounts;
    if (this.auth.user.roles.isAdmin || this.auth.user.roles.isWelbyClinical) {
      if (manufacturer) {
        return this.fsService.col$('hardware_not_mapped', (ref) => ref.where('device.imei', '>=', imei).where('device.manufacturer', '==', manufacturer));
      } else {
        return this.fsService.col$('hardware_not_mapped', (ref) => ref.where('device.imei', '>=', imei));
      }
    } else if (availableClients.length <= this.maxClientAccounts) {
      return manufacturer
        ? this.fsService.col$('hardware_not_mapped', (ref) =>
            ref.where('client_id', 'in', availableClients).where('device.imei', '>=', imei).where('device.manufacturer', '==', manufacturer)
          )
        : this.fsService.col$('hardware_not_mapped', (ref) => ref.where('client_id', 'in', availableClients).where('device.imei', '>=', imei));
    } else {
      const chunks$ = this.utilsService.getChunksObsWhenArrayLengthIsGreaterThanMaxValue(availableClients);

      return chunks$.pipe(
        map((chunk) => this.fsService.col$('hardware_not_mapped', (ref) => ref.where('client_id', 'in', chunk).where('device.imei', '>=', imei))),
        mergeAll()
      );
    }
  }

  getMappedDevicesByUserAndPatient(userId: string, patientId: string): Observable<any> {
    return this.http.get(environment.welbyEndpoint + `/api/v1/device/mapping/user/${userId}/patient/${patientId}`);
  }

  mapPatientToInventoryDevice(imei: string, patient: PatientVO) {
    return this.http.post(environment.welbyEndpoint + `/api/v1/device/mapping/map/${imei}`, patient);
  }

  unmapDevice(imei: string) {
    return this.http.get(environment.welbyEndpoint + `/api/v1/device/mapping/unmap/${imei}`);
  }

  unlinkDevice(imei: string, patientId: string, manufacturer: string) {
    return this.http.post(environment.welbyEndpoint + `/api/v1/device/unlink`, { imei, patientId, vendorId: manufacturer });
  }

  getDeviceUserMapping(id: string) {
    return this.fsService.doc(`device_user_mapping/${id}`).get().pipe(take(1)).toPromise();
  }

  updateAutoRefilInDeviceUserMapping(id: string, auto_refill: boolean) {
    return this.fsService.update(`device_user_mapping/${id}`, { auto_refill });
  }

  suspendDevice(device: DeviceVO): Observable<any> {
    return this.http.post(environment.welbyEndpoint + `/api/v1/device-utils/suspend/${device.manufacturer}/${device.imei}`, {});
  }
}
