import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { client_rates_default, client_services_default, client_vendors_default } from 'src/app/models/clients/client-default-values';
import { Patient } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';

export interface CreateUserDto {
  practice: string;
  fName: string;
  lName: string;
  uid?: string;
  clientId?: string;
  practiceAdmin?: boolean;
  provider?: boolean;
  patient?: boolean;
  pwHash?: string;
  npi?: string;
  phoneNumber?: string;
}

@Injectable({ providedIn: 'root' })
export class UserFactory {
  constructor(private db: AngularFirestore) {}

  async createNewClient(emailAddress: string, data: CreateUserDto) {
    // create a new client object
    const newClient = { client_name: data.practice, client_npi: data.npi };
    // get a reference to the clients collection
    const clientsRef: DocumentReference = await this.db.collection('clients').add(newClient);
    // get the client id
    const clientId = clientsRef.id;

    try {
      await clientsRef.set(
        {
          active: true,
          billing_approved: false,
          ccm_clinical: { active: true, rate: 30 },
          client_id: clientId,
          client_name: data.practice,
          client_npi: data.npi,
          device_vendors: client_vendors_default,
          emr_data: {},
          rates: client_rates_default,
          rpm_clinical: { active: true, rate: 30 },
          software_license: { active: true, rate: 40 },
          start_date: new Date(),
          patients_loaded: 0,
          service: client_services_default,
        },
        { merge: true }
      );
    } catch (e) {
      throw new Error('Error trying to set client data');
    }

    try {
      const details = {
        active: true,
        name: `${data.fName} ${data.lName}`,
        primary_email: emailAddress,
        primary_phone: '',
        roles: {
          admin: true,
          billing: true,
          clinical: true,
        },
        uid: clientId,
      };
      await clientsRef.collection('client_contacts').doc(clientId).set(details);
    } catch (e) {
      throw new Error('Error trying to set client contacts data');
    }
    return clientId;
  }

  async createUserAccount(emailAddress: string, data: CreateUserDto): Promise<Patient> {
    try {
      const activeUser = {
        client_access: [data.clientId],
        client_accounts: [data.clientId],
        client_responsible: data.practice,
        client_responsible_id: data.clientId,
        dob: new Date(),
        email: emailAddress,
        pw_hash: data.pwHash,
        firstName: data.fName,
        lastName: data.lName,
        phone: data.phoneNumber ? data.phoneNumber : 'N/A',
        profileImageURL: `${environment?.defaultPhotoUrl ?? ''}`,
        force_qwins: false,
        ssn_id: 'n/a',
        timeStamp: new Date(),
        user_id: data.uid,
        roles: {
          isPatient: data.patient,
          isClient: data.provider,
          isAdmin: false,
          isPracticeAdmin: data.practiceAdmin,
          isWelbyClinical: false,
          isManagerNurse: false,
        },
      };

      await this.db.collection('users').doc(data.uid).set(activeUser);
      return activeUser;
    } catch (error) {
      return null;
    }
  }
}
