import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { Patient, ViewOnChart } from 'src/app/models/patient.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { DataService } from 'src/app/services/data.service';
import { InitialTourService } from 'src/app/services/initial-tour.service';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';
import { RoleModel } from 'src/app/services/models/role-model.service';
import { ThemeService } from 'src/app/services/theme.service';

import { PatientService } from 'src/app/services/patient.service';
import { ProfileConfigurationService } from 'src/app/services/profile-configuration.service';
import * as version from '../../../assets/version.json';
import { BugReportDialogComponent } from '../dialogs/bug-report-dialog/bug-report-dialog.component';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  versionStr = `v ${version.major}.${version.minor}.${version.patch}`;
  isLightMode$: Observable<boolean>;

  themeToggleControl = new FormControl(false);
  showTour$: Observable<boolean>;
  userConnected$: Observable<ViewOnChart[]>;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.Small, Breakpoints.Medium, Breakpoints.TabletPortrait, Breakpoints.TabletLandscape])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    public data: DataService,
    public auth: NewAuthService,
    public roleModel: RoleModel,
    private themeService: ThemeService,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private initialDataService: InitialDataModel,
    private initialTourService: InitialTourService,
    private profileConfigurationService: ProfileConfigurationService,
    public patientService: PatientService
  ) {}

  @HostListener('document:visibilitychange', ['$event'])
  visibilityChangeHandler(event: Event) {
    if (document.visibilityState === 'visible') {
      this.reattachBeforeUnload();
    }
  }

  reattachBeforeUnload() {
    this.addUserConnectedListener();
  }

  ngOnInit() {
    this.isLightMode$ = this.themeService.isLightModeObservable$;
    this.addUserConnectedListener();
    this.setChangesListenerThemeToggle();
    this.showTour$ = this.initialDataService.isTourShownObservable$;
    this.showTour$
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged(),
        filter((showTour) => showTour === true)
      )
      .subscribe(() => this.startTour());
    this.auth.isLoggedObservable$
      .pipe(takeUntil(this.unsubscribe))
      .pipe(take(1))
      .subscribe((isLogged) => {
        if (isLogged) {
          this.getProfileTheme();
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.checkIfSpecificRoute();
      });
  }

  checkIfSpecificRoute() {
    if (this.router.url.includes('clinical/patient-detail')) {
      this.addUserConnectedListener();
    } else {
      this.userConnected$ = null;
    }
  }

  addUserConnectedListener(): void {
    this.userConnected$ = this.patientService?.patient$?.pipe(
      map((patient) =>
        patient?.viewers_on_chart?.map((viewer) => ({
          ...viewer,
          tooltip: viewer.user_id !== this.auth.user?.user_id ? `${viewer.name} connected` : 'You',
          initials: viewer?.name?.split(' ').length > 1 ? `${viewer.name[0].toUpperCase()}${viewer.name.split(' ')[1][0].toUpperCase()}` : `${viewer.name[0].toUpperCase()}`,
        }))
      )
    );
  }

  getProfileTheme() {
    this.profileConfigurationService
      .getProfileConfiguration(this.auth.user?.user_id, 'theme')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((profileConfiguration) => {
        this.themeToggleControl.setValue(profileConfiguration?.default_theme === 'light');
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  setChangesListenerThemeToggle(): void {
    this.themeToggleControl.valueChanges.subscribe((lightMode) => {
      const theme = lightMode ? 'light' : 'dark';
      this.themeService.toggleDarkMode(theme);
    });
  }

  openBugReportForm(): void {
    const dialogRef = this.matDialog.open(BugReportDialogComponent, {
      width: '700px',
      maxWidth: '100vw',
    });
  }

  navigateToPatientDetail(patient: Patient): void {
    this.router.navigateByUrl(`/clinical/patient-detail/${patient.user_id}`);
  }

  navigateToIntegrationPage(): void {
    this.router.navigateByUrl(`/integration`);
  }

  logout() {
    this.auth.signOut();
    this.initialDataService.clearSubscriptions();
    this.initialDataService.resetValues();
  }

  startTour() {
    this.initialTourService.startTour();
  }
}
