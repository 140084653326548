import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Patient } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';

import { PatientConsentDialogComponent } from '../dialogs/practice/patient-consent-dialog/patient-consent-dialog.component';
import { ClientContact } from '../models/client.model';
import { ProviderDialogComponent } from './../dialogs/practice/provider-dialog/provider-dialog.component';
import { NewAuthService } from './auth/new-auth-service.service';
import { SnackService } from './snack.service';

@Injectable({
  providedIn: 'root',
})
export class PracticeService {
  isLoading = false;
  isSendingConsent = false;

  constructor(
    private dialog: MatDialog,
    private auth: NewAuthService,
    private db: AngularFirestore,
    private snack: SnackService,
    private http: HttpClient
  ) {}

  addProvider() {
    throw new Error('Method not implemented.');
  }

  openProviderDialog(): void {
    const dialogRef = this.dialog.open(ProviderDialogComponent, {
      width: '500px',
      data: { provider: {} },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const data = {
          email: result.provider.email,
          firstName: result.provider.firstName,
          lastName: result.provider.lastName,
          client_responsible_id: result.provider.client.client_id,
          requestor: this.auth.user.user_id,
          requestorEmail: this.auth.user.email,
        };
        await this.db.collection('signup_requests').add(data);
        this.snack.patientLoaded(`Request has been sent to ${data.firstName}`);
      }
    });
  }

  openConsentDialog(): void {
    console.log('new consent');

    const url = environment.welbyEndpoint + '/api/v1/integrations/panda-docs/create/consent';

    const dialogRef = this.dialog.open(PatientConsentDialogComponent, {
      width: '500px',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // console.log('result', result);
        this.isSendingConsent = true;
        // create the payload to send for the document request.
        const documentPayload = {
          first_name: result.first_name,
          last_name: result.last_name,
          email: result.email,
          client_id: this.auth.user.client_responsible_id,
        };

        this.http.post(url, documentPayload).subscribe(
          (val) => {
            console.log('POST call successful value returned in body', val);
          },
          (response) => {
            console.log('Error posting the document', response);
          },
          () => {
            console.log('Document create completed');
            this.isSendingConsent = false;
            this.snack.consentDelivered(true);
          }
        );
        return;
      }
    });
  }

  // first create the consent document
  async createConsent(url, document_payload, httpOptions) {
    this.isSendingConsent = true;
    let document_id = '';

    await this.http.post(url, document_payload, httpOptions).subscribe(
      (val) => {
        // console.log('POST call successful value returned in body', val);
        // if POST is successful, then second post sends the email
        document_id = val['id'];
      },
      (response) => {
        console.log('Error posting the document', response);
        // set the spinner to false and create an error message
        this.isSendingConsent = false;
        this.snack.consentDelivered(false);
      },
      () => {
        console.log('Document create completed');
        setTimeout(() => {
          this.sendConsent(url, document_id, httpOptions);
        }, 3000);
      }
    );
    return;
  }

  async sendConsent(url: string, document_id: string, httpOptions) {
    const send_url = `${url}/${document_id}/send`;

    const email_payload = {
      message: 'Please eSign the attached patient consent form to participate in the Welby Health remote monitoring program',
      subject: 'Welby Health - Patient Consent Form',
      silent: false,
    };

    await this.http.post(send_url, email_payload, httpOptions).subscribe(
      (val) => {
        console.log('Email created successfully', val);
      },
      (response) => {
        console.log('Email POST created an error response', response);
        this.isSendingConsent = false;
        this.snack.consentDelivered(false);
      },
      () => {
        console.log('POST to email completed successfully');
        this.isSendingConsent = false;
        this.snack.consentDelivered(true);
      }
    );
  }

  async createEditProvider(patient: Patient, isNew: boolean, id?: string) {
    console.log('adding provider');
    const uid = patient.user_id;
    const userRef = this.db.collection('users');
    if (isNew) {
      this.createBackEndProvider(patient, true);
      console.log('new user', patient, isNew);
    } else {
      userRef.doc(id).set(patient, { merge: true });
      console.log('user edited', patient);
      this.isLoading = false;
      this.snack.patientLoaded('test');
    }
  }

  createBackEndProvider(provider: Patient, isNew: boolean) {
    console.log('trying to create provider on the backend');

    this.http.post(`${environment.firebaseURL}/backendUser/createProvider`, provider, { responseType: 'text' }).subscribe(
      (val) => {
        // this.afAuth.auth.sendPasswordResetEmail(provider.email);
        this.isLoading = false;
        this.snack.patientLoaded(val);
        console.log('POST call successful value returned in body', val);
      },
      (response) => {
        console.log('POST really screwed something up', response);
      },
      () => {
        console.log('The POST observable is now completed.');
      }
    );
  }

  handleClientContact(isNew: boolean, contact: ClientContact, client_id: string, contact_id?: string, isDelete?: boolean) {
    if (isNew) {
      return this.db.collection('clients').doc(client_id).collection('client_contacts').add(contact);
    } else if (isDelete) {
      return this.db.collection('clients').doc(client_id).collection('client_contacts').doc(contact_id).delete();
    } else {
      return this.db.collection('clients').doc(client_id).collection('client_contacts').doc(contact_id).set(contact, { merge: true });
    }
  }
}
