import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Patient, Roles } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import { SnackService } from '../snack.service';

import { AngularFireFunctions } from '@angular/fire/functions';
import { User } from 'src/app/models/user.model';
import { TokenService } from './token.service';
import { CreateUserDto, UserFactory } from './user.factory';

@Injectable({
  providedIn: 'root',
})
export class NewAuthService {
  client_id: string;
  client_npi: string;
  user: Patient;
  user$: Observable<Patient>;
  isLoggedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLoggedObservable$: Observable<boolean> = this.isLoggedSubject.asObservable();
  needsTakeOverSubject: Subject<boolean> = new Subject();
  needsTakeOverObservable$: Observable<boolean> = this.needsTakeOverSubject.asObservable();
  mfaResolver;
  emailVerified = false;
  emailsAlreadyRegistered = [];

  private authenticated = false;
  private tempCreds: { email: string; password: string } = { email: '', password: '' };

  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private tokenService: TokenService,
    private userFactory: UserFactory,
    private httpClient: HttpClient,
    private router: Router,
    private snackService: SnackService,
    private readonly fireFns: AngularFireFunctions
  ) {
    this.afAuth.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.emailVerified = user.emailVerified;
      }
    });
  }

  // check if user has mfa enabled
  isMfaEnabled(): boolean {
    return this.afAuth.auth.currentUser.multiFactor.enrolledFactors.length > 0;
  }

  emitTakeOverStatus(): void {
    if (this.emailVerified) {
      this.needsTakeOverSubject.next(false);
    } else {
      this.needsTakeOverSubject.next(true);
    }
  }

  setNeedsTakeOver(value: boolean): void {
    this.needsTakeOverSubject.next(value);
  }

  async resolveMFA(userCredential: firebase.auth.UserCredential) {
    if (this.mfaResolver) {
      try {
        await this.validateAuthentication(this.tempCreds.email, this.tempCreds.password, userCredential);
      } catch (error) {
        throw new Error('Error validating authentication');
      }
      this.checkIfRoute(true);
      return this.user;
    }
  }

  async signIn(email: string, password: string): Promise<Patient> {
    // throw an error if the user is already authenticated
    if (this.authenticated) {
      throw new Error('Already authenticated');
    }

    // authenticate the user with angular fire
    let userCredential: firebase.auth.UserCredential;
    try {
      userCredential = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      if (e.code === 'auth/multi-factor-auth-required') {
        this.tempCreds = { email, password };
        this.mfaResolver = e.resolver;
        throw new Error('Multi-factor authentication required');
      } else {
        throw e;
      }
    }

    await this.validateAuthentication(email, password, userCredential);
    this.checkIfRoute(true);

    return this.user;
  }

  async signUpAsProvider(email: string, password: string, dto: CreateUserDto, routeAfterSignUp: boolean = true): Promise<Patient> {
    if (this.authenticated) {
      throw new Error('Already authenticated');
    }

    try {
      // Create the user with angular fire
      const userCredential: firebase.auth.UserCredential = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
      dto.uid = userCredential.user.uid;
      dto.practice = 'none';
      dto.practiceAdmin = false;
      dto.provider = true;
      dto.patient = false;
      dto.pwHash = Md5.hashStr(password);

      // create user account and store in the database
      const user = await this.userFactory.createUserAccount(email, dto);
      this.user = user;

      if (user && user.client_responsible_id) {
        await this.setEmailVerifiedInTrue(dto);
      }

      await this.validateAuthentication(email, password, userCredential);

      // search for the user by email in the "signup_requests" table and delete it
      await this.db
        .collection('signup_requests')
        .ref.where('email', '==', email)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            doc.ref.delete();
          });
        });

      return this.user;
    } catch (error) {
      console.error(error);
      this.snackService.genericSnackBar(`Error in signIn ${error.message}`, ['error-snackbar']);
    }
  }

  async signUp(email: string, password: string, isProvider: boolean, dto: CreateUserDto, routeAfterSignUp: boolean = true): Promise<Patient | any> {
    if (this.authenticated) {
      throw new Error('Already authenticated');
    }

    try {
      // Create the user with angular fire
      const userCredential: firebase.auth.UserCredential = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);

      this.client_id = await this.userFactory.createNewClient(email, dto);

      if (isProvider) {
        dto.clientId = this.client_id;
        dto.uid = userCredential.user.uid;
        dto.practiceAdmin = true;
        dto.provider = true;
        dto.patient = false;
        dto.pwHash = Md5.hashStr(password);
      } else {
        dto.uid = userCredential.user.uid;
        dto.practice = 'none';
        dto.practiceAdmin = false;
        dto.provider = false;
        dto.patient = true;
        dto.pwHash = Md5.hashStr(password);
      }

      const user = await this.userFactory.createUserAccount(email, dto);

      if (user && user.client_responsible_id) {
        await this.setEmailVerifiedInTrue(dto);
      }

      await this.validateAuthentication(email, password, userCredential);

      return user;
    } catch (error) {
      console.error(error);
      this.snackService.genericSnackBar(`Error in signIn: ${error.message}`, ['error-snackbar']);
      throw error;
    }
  }

  async validateAuthentication(email: string, password: string, userCredential: firebase.auth.UserCredential): Promise<Patient> {
    // add a 1 second wait to ensure the user is authenticated
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Store the user in a user variable
      const userDoc = await this.db.collection('users').doc(userCredential.user.uid).ref.get();
      this.user = userDoc.data() as User;
      userDoc.ref.set({ pw_hash: Md5.hashStr(password) }, { merge: true });

      // Store the access token in the local storage
      try {
        await this.tokenService.requestNewToken(email, Md5.hashStr(password));
      } catch (error) {
        throw new Error('Error validating authentication');
      }

      // get a user observable for live updates
      this.user$ = this.afAuth.authState.pipe(
        switchMap((user) => {
          if (user) {
            return this.db.doc<User>(`users/${userCredential.user.uid}`).get().pipe();
          } else {
            return of(null);
          }
        })
      );
      // Set the authenticated flag to true
      this.authenticated = true;

      return this.user;
    } catch (error) {
      throw new Error('Error validating authentication');
    }
  }

  async signOut(): Promise<boolean> {
    await this.afAuth.auth.signOut();
    this.tokenService.destroyToken();
    this.authenticated = false;
    this.router.navigate(['login']);
    this.isLoggedSubject.next(false);
    return true;
  }

  doLandingPageRoute(): Promise<boolean> {
    if (this.user?.roles?.isClient) {
      return this.router.navigateByUrl('clinical');
    } else if (this.user?.roles?.isPracticeAdmin) {
      return this.router.navigateByUrl('practice');
    } else if (this.user?.roles?.isPatient) {
      return this.router.navigate(['consumer', this.user.user_id]);
    }
  }

  sendVerificationEmailForProvider(email: string, clientId: string, userId: string, first_name: string, last_name: string): Promise<void> {
    const baseUrl = window.location.href.match(/^(.*?\/\/.*?\/).*$/)[1];
    const url = `${baseUrl}new-provider-signup?step=2&clientId=${clientId}&userId=${userId}`;
    const callable = this.fireFns.httpsCallable('firebaseValidateEmail');
    return callable({ email, link: url, first_name, last_name }).pipe(take(1)).toPromise();
  }

  sendVerificationEmail(email: string): Promise<void> {
    const baseUrl = window.location.href.match(/^(.*?\/\/.*?\/).*$/)[1];
    const url = `${baseUrl}login`;
    return this.afAuth.auth.sendSignInLinkToEmail(email, { url, handleCodeInApp: true });
  }

  resetPassword(email: string = null): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email ?? this.user.email);
  }

  forgotPassword(): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(this.user.email);
  }

  signInUsingToken(uid): Observable<any> {
    // Renew token
    return this.httpClient
      .post(environment.welbyEndpoint + '/api/v1/auth/refresh-access-token', {
        accessToken: this.tokenService.getToken(),
        uid,
      })
      .pipe(
        catchError(() =>
          // Return false
          this.signOut().then(() => false)
        ),
        switchMap((response: any) => {
          if (!response) {
            return this.signOut().then(() => false);
          }

          // Store the enw token
          this.tokenService.setToken(response.access_token);
          // Set the authenticated flag to true
          this.authenticated = true;
          // store the user object from the response in the user variable
          this.user = response.user;
          // get a user observable for live updates
          this.user$ = this.afAuth.authState.pipe(
            take(1),
            switchMap((user) => {
              if (user) {
                return this.db.doc<User>(`users/${user.uid}`).valueChanges();
              } else {
                return of(null);
              }
            })
          );
          return of(true);
        })
      );
  }

  async checkingIfEmailIsAlreadyInFB(email: string, password = ' ') {
    try {
      if (this.emailsAlreadyRegistered.findIndex((emailSaved) => email === emailSaved) !== -1) {
        return false;
      }
      await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        this.emailsAlreadyRegistered.push(email);
        return false;
      } else {
        return true;
      }
    }
  }

  async setEmailVerifiedInTrue(dto: CreateUserDto) {
    const { phoneNumber, ...withoutNumber } = dto;
    const response = await this.autoValidateEmail(withoutNumber).catch((error) => {
      this.snackService.genericSnackBar('Error validating email', ['error-snackbar']);
      console.error(error);
    });
    this.emailVerified = response?.success ? true : false;
  }

  async checkIfRoute(routeAfterSignUp: boolean) {
    if (routeAfterSignUp) {
      // rout the user to the correct landing page
      const isRedirected = await this.doLandingPageRoute();
      if (isRedirected) {
        this.isLoggedSubject.next(true);
        this.tempCreds = null;
      }
    }
  }

  getAfAuthUser(): Promise<firebase.User> {
    return this.afAuth.user.toPromise();
  }

  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this.authenticated) {
      this.isLoggedSubject.next(true);
      return of(true);
    }

    // Check the access token availability
    if (!this.tokenService.getToken()) {
      this.isLoggedSubject.next(false);
      return of(false);
    }

    // Check if the token is expired
    if (this.tokenService.tokenIsExpired()) {
      this.isLoggedSubject.next(false);
      return of(false);
    }

    // Check if user is authenticated in firebase
    return this.afAuth.authState.pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          this.isLoggedSubject.next(true);
          return this.signInUsingToken(user.uid);
        } else {
          this.isLoggedSubject.next(false);
          return of(false);
        }
      })
    );
  }

  isClientAccount() {
    return this.user
      ? of(this.user.roles.isClient)
      : this.afAuth.authState.pipe(
          take(1),
          switchMap(async (user) => {
            if (user) {
              const userDoc = await this.db.collection('users').doc(user.uid).ref.get();
              const currentUser = userDoc.data() as Patient;
              return of(currentUser.roles.isClient);
            } else {
              return of(false);
            }
          })
        );
  }

  getUserRoles(): Observable<Roles | null> {
    return this.user
      ? of(this.user.roles)
      : (this.afAuth.authState.pipe(
          take(1),
          switchMap(async (user) => {
            if (user) {
              const userDoc = await this.db.collection('users').doc(user.uid).ref.get();
              const currentUser = userDoc.data() as Patient;
              return currentUser.roles;
            } else {
              return null;
            }
          })
        ) as Observable<Roles | null>);
  }

  async autoValidateEmail(userDto): Promise<any> {
    const url = `${environment.firebaseURL}/backendUser/autoValidateEmail`;
    return this.httpClient.post(url, userDto).toPromise();
  }

  /**
   * Change the email login of a patient
   *
   * @param email new Email
   * @param patientId patient id
   * @returns promise<void>
   */
  changeEmail(email: string, patientId: string): Promise<any> {
    const callable = this.fireFns.httpsCallable('change_auth_email');
    const data = { email, uid: patientId };
    return callable(data).pipe(take(1)).toPromise();
  }

  getIfUserHasAIAuthorization(): Observable<boolean> {
    const email = this.user.email;
    return this.db
      .collection('ai_active_users')
      .doc('ai-messages')
      .get()
      .pipe(map((doc) => (doc.exists ? doc.data().active.some((userEmail: string) => userEmail === email) : false)));
  }

  getAiMessageValue(userId: string): Observable<boolean> {
    return this.db
      .collection('users')
      .doc(userId)
      .get()
      .pipe(map((doc) => doc.data()?.enableAImessage || false));
  }
}
