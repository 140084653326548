import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { Patient } from 'src/app/models/patient.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';

import { ClinicalTask, ClinicalTaskListRequest, ClinicalTaskListResponse, ClinicalTaskRequest, SearchClinicalTasksParams } from 'src/app/models/clients/clinical-task.model';
import { DataService } from '../../../services/data.service';
import { PatientService } from '../../../services/patient.service';
import { TaskService } from './services/task.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-task-history',
  templateUrl: './task-history.component.html',
  styleUrls: ['./task-history.component.scss'],
})
export class TaskHistoryComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['patient', 'task_name', 'assignee_id', 'due_date', 'chart', 'close'];
  taskList = new MatTableDataSource<ClinicalTask>();
  showMyAssignedTasks = true;
  currentUser: Patient;
  loading = false;
  assignedView = true;
  allDatesView = false;
  tasksObject = {};
  allTasks: ClinicalTask[] = [];
  filteredTasks = [];
  currentTask: any;
  currentTaskId: string;
  showDetail = false;
  showNotes = false;
  datesOptions = [];
  filterForm: FormGroup;
  subscription: Subscription = new Subscription();
  tasksByState = [];
  searchParams: any = { assigned: this.assignedView, completed: false };
  activePatient: Patient;
  currentPage = 1;
  pageSize = 10;
  totalRecords = 0;

  constructor(
    public db: AngularFirestore,
    private authService: NewAuthService,
    public data: DataService,
    private patientService: PatientService,
    private router: Router,
    private fb: FormBuilder,
    private utilsService: UtilsService,
    private taskService: TaskService,
    private snackService: SnackService
  ) {}

  ngOnInit(): void {
    this.tasksByState = [
      { label: 'All', value: null },
      { label: 'Completed', value: true },
      { label: 'Open Tasks', value: false },
    ];
    this.datesOptions = [
      { startDate: null, endDate: null, label: 'All' },
      { startDate: this.utilsService.getTodayDate(), endDate: this.utilsService.getTodayEODDate(), label: 'Today tasks' },
      {
        startDate: this.utilsService.getLastWeekDate(this.patientService?.currentPatientService?.timezone),
        endDate: this.utilsService.getTodayEODDate(),
        label: 'Last week tasks',
      },
      {
        startDate: this.utilsService.getLastMonthDate(this.patientService?.currentPatientService?.timezone),
        endDate: this.utilsService.getTodayEODDate(),
        label: 'Last month tasks',
      },
    ];
    this.filterForm = this.fb.group({
      dateFilter: [''],
      taskByState: [this.tasksByState[2]],
    });
    this.currentUser = this.authService.user;
    this.getClinicalTasks(this.searchParams);
    this.setFilterChanges();
    this.activePatient = this.patientService.currentPatientService;

    if (this.activePatient) {
      this.displayedColumns = ['task_name', 'assignee_id', 'due_date', 'close'];
    }
  }

  onToggleChange(event: MatSlideToggleChange): void {
    this.showMyAssignedTasks = event.checked;
    this.currentPage = 1;
    this.getClinicalTasks(this.searchParams);
  }

  setFilterChanges() {
    this.filterForm.get('dateFilter').valueChanges.subscribe((dateSelected) => {
      this.searchParams = { ...this.searchParams, startDate: dateSelected?.startDate, endDate: dateSelected?.endDate };
      if (dateSelected) {
        this.getClinicalTasks(this.searchParams);
      } else {
        this.getClinicalTasks();
      }
    });
    this.filterForm.get('taskByState').valueChanges.subscribe((taskState) => {
      this.searchParams = { ...this.searchParams, completed: taskState.value };
      if (taskState) {
        this.getClinicalTasks(this.searchParams);
      } else {
        this.getClinicalTasks();
      }
    });
  }

  getClinicalTasks(searchParams: { completed?: boolean; assigned?: boolean; startDate?: Date; endDate?: Date } = {}) {
    this.loading = true;
    const { startDate, endDate, completed } = searchParams;
    const searchParamsTask = {
      isCompleted: completed,
      startDate,
      endDate,
      sortByField: 'due_date',
      sortDirection: 'asc',
    } as SearchClinicalTasksParams;
    if (this.patientService.currentPatientService?.user_id) {
      searchParamsTask.patientId = this.patientService.currentPatientService.user_id;
    }
    const searchParamsEndpoint: ClinicalTaskListRequest = {
      page: this.currentPage,
      pageSize: this.pageSize,
      providerId: this.currentUser?.user_id,
      searchParams: searchParamsTask,
    };

    if (this.showMyAssignedTasks == true) {
      this.taskService.getTasksByProvider(searchParamsEndpoint).subscribe((response: ClinicalTaskListResponse) => {
        this.getTasks(response);
      });
    } else {
      const search: ClinicalTaskRequest = {
        page: this.currentPage,
        pageSize: this.pageSize,
        isCompleted: completed,
      };
      this.taskService.getTasksByPatient(this.patientService.currentPatientService.user_id, search).subscribe((response: ClinicalTaskListResponse) => {
        this.getTasks(response);
      });
    }
  }

  getTasks(response: ClinicalTaskListResponse): void {
    this.taskList.data = response.records.map((task: ClinicalTask) => ({ ...task, due_date: new Date(task.due_date) }));
    this.totalRecords = response.totalRecords;
    if (!this.taskList.sort) {
      this.taskList.sort = this.sort;
    }
    this.loading = false;
  }

  changePage(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.getClinicalTasks(this.searchParams);
  }

  async viewPatient(id: string) {
    // could set the active navbar patient here.
    this.router.navigate(['clinical', 'patient-detail', id]);
  }

  closeTask(clinicalTask: ClinicalTask) {
    this.taskService.markTaskAsCompleted(clinicalTask).subscribe((resp) => {
      this.snackService.genericSnackBar('Task completed', ['success-snackbar'], 5000);
      this.getClinicalTasks(this.searchParams);
    });
  }

  toggleAsignedOwned() {
    this.assignedView = !this.assignedView;
    this.searchParams = { ...this.searchParams, assigned: this.assignedView };
    this.getClinicalTasks(this.searchParams);
  }

  filterPastDue() {
    const now = new Date();
    this.allDatesView = !this.allDatesView;

    if (this.assignedView && this.allDatesView) {
      this.filteredTasks = this.allTasks.filter((p) => p.assignee_id === this.currentUser.user_id && p.due_date.toDate() <= now);
    } else if (this.assignedView && !this.allDatesView) {
      this.filteredTasks = this.allTasks.filter((p) => p.assignee_id === this.currentUser.user_id);
    } else if (!this.assignedView && this.allDatesView) {
      this.filteredTasks = this.allTasks.filter((p) => p.creator_id === this.currentUser.user_id);
    } else {
      this.filteredTasks = this.allTasks.filter((p) => p.creator_id === this.currentUser.user_id && p.due_date.toDate() <= now);
    }
  }

  editTask(task): void {
    this.currentTask = task;
    this.toggleDetail();
  }

  clearCurrentTask(event: boolean): void {
    if (event) {
      this.currentTask = null;
      this.showDetail = false;
      this.showNotes = false;
      this.getClinicalTasks(this.searchParams);
    }
  }

  openNotes(event): void {
    this.currentTask = event;
    this.showNotes = true;
    this.showDetail = false;
  }

  toggleDetail(isNew?: boolean) {
    if (isNew) {
      this.currentTask = this.patientService.currentPatientService
        ? {
            patient_id: this.patientService.currentPatientService.user_id,
          }
        : null;
    }
    this.showDetail = true;
    this.showNotes = false;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
