import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import * as moment from 'moment-timezone';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { VitalRecord } from 'src/app/models/vitals/vital-measure';
import { DataService } from 'src/app/services/data.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { MeasureValueService } from 'src/app/services/measures/measure-value.service';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import { environment } from 'src/environments/environment';
import { VitalFlagCategory } from '../../rpm/vital-flag-criteria/models/vital_flags.model';
import { ReportModel } from '../models/report.model';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class CustomReportService {
  logoUrl = 'https://static1.squarespace.com/static/5ed86f2562d6cd64a09d7fc3/t/5ed86f4ee466db3647183018/1593541742088/?format=1500w';
  notes;
  vitals;
  alerts;
  eomMapResults: Map<string, any> = new Map();
  baseUrl = `${environment.welbyEndpoint}/api/v1`;

  constructor(
    private fs: FirestoreService,
    private dataService: DataService,
    private patientService: PatientService,
    private snackService: SnackService,
    private measureService: MeasureValueService,
    private http: HttpClient
  ) {}

  generateReport(reportObject: ReportModel): Observable<ArrayBuffer> {
    const url = `${this.baseUrl}/rpm-reports`;
    return this.http.post(url, reportObject, { responseType: 'arraybuffer' });
  }

  async getEOMData(reportObject: ReportModel) {
    const dataLastMonth = await this.getReportDataFromOptions(reportObject);
    const reportObjectPrevLastMonth = {
      ...reportObject,
      startDate: moment(reportObject.startDate).subtract(1, 'months').toDate(),
      endDate: reportObject.startDate,
    };
    const dataPrevLastMonth = await this.getReportDataFromOptions(reportObjectPrevLastMonth);
    const vitalsLastMonthMap = this.buildVitalMap(dataLastMonth?.vitals);
    const vitalsPrevLastMonthMap = this.buildVitalMap(dataPrevLastMonth?.vitals);
    const result = {};

    vitalsLastMonthMap.forEach((value, key) => {
      const prevLastMoth = vitalsPrevLastMonthMap.has(key) ? vitalsPrevLastMonthMap.get(key) : [];
      result[key] = {
        count: value.length,
        max: this.getMaxVital(value),
        avg30: this.calculateAvg([...value]),
        avg60: this.calculateAvg([...value, ...prevLastMoth]),
      };
    });

    return result;
  }

  async generateEOMReport(reportObject): Promise<string> {
    if (this.eomMapResults.has(this.patientService.currentPatientService.user_id)) {
      return this.eomMapResults.get(this.patientService.currentPatientService.user_id);
    }
    const data = await this.getEOMData(reportObject);
    let result = `<h3>Month-end summary completed</h3>`;
    Object.keys(data).forEach((type) => {
      result = `${result}<h4>Monitoring: ${type}</h4>`;
      result = `${result}<p>Patient took ${data[type].count} ${data[type].count > 1 ? 'measurements' : 'measurement'} in 30 days</p>`;
      result = `${result}<p><strong>30 days average</strong>: ${this.printVitalByType(type, data[type].avg30)}</p>`;
      result = `${result}<p><strong>60 days average</strong>: ${this.printVitalByType(type, data[type].avg60)}</p>`;
      result = `${result}<p><strong>Highest monthly ${type}: ${this.printMaxVitalByType(type, data[type].max)}</strong></p>`;
    });
    this.eomMapResults.set(this.patientService.currentPatientService.user_id, result);
    return result;
  }

  formatDate(timestamp: any, format = 'MM/DD/YY hh:mm A z') {
    const d = timestamp.toDate();
    const patientTimezone = this.patientService.currentPatientService.timezone.nameValue ?? 'UTC';
    const m = moment.tz(d, patientTimezone);
    return m.format(format);
  }
  async exportPDFwithChart(image: string, startDate: Date, endDate: Date, vitals: VitalRecord[]) {
    let vitalTable = [];
    vitalTable.push([
      { text: 'Type', bold: true },
      { text: 'Source', bold: true },
      { text: 'Measure', bold: true },
      { text: 'Date', bold: true },
      { text: 'Comments', bold: true },
    ]);
    if (vitals && vitals.length > 0) {
      vitalTable = [...vitalTable, ...this.transformVitalsDataToTable(vitals)];
    }
    const document = await this.buildContentToPdfWithChart(image, startDate, endDate, vitalTable);
    this.exportPDF(document);
  }

  exportPDF(doc: any) {
    try {
      pdfMake.createPdf(doc).open();
    } catch (error) {
      console.error(error);
      this.snackService.genericSnackBar('Error during creating PDF report', ['error-snackbar'], 4000);
    }
  }

  private printVitalByType(type: string, vitalMap: any) {
    switch (type) {
      case 'Blood Pressure':
        return `${vitalMap.sbp} / ${vitalMap.dbp} mmHg - Heart Beat ${vitalMap.pulse_bpm} bpm`;
      case 'Blood Glucose':
        return `${vitalMap.mgdl} mgDl`;
      case 'Weight':
        return `${vitalMap.lbs} lbs`;
      case 'Sleep':
        let result = vitalMap.duration ? `Duration: ${vitalMap.duration} min ` : '';
        result = !result ? (vitalMap.efficiency ? `Efficiency: ${vitalMap.efficiency}%` : '') : result;
        return result;
      case 'Calories':
        return `${vitalMap.calories} Calories`;

      default:
        return `${vitalMap ? JSON.stringify(vitalMap) : ''}`;
    }
  }

  private printMaxVitalByType(type: string, vitalMap: any) {
    switch (type) {
      case 'Blood Pressure':
        return `${vitalMap.sbp?.vital?.value?.sbp} / ${vitalMap.sbp?.vital?.value?.dbp} mmHg`;

      case 'Blood Glucose':
        return `${vitalMap.mgdl?.value} mgDl`;
      case 'Weight':
        return `${vitalMap.lbs?.value} lbs`;
      case 'Sleep':
        let result = vitalMap.duration?.value ? `Duration: ${vitalMap.duration?.value} min ` : '';
        result = vitalMap.efficiency?.value ? `Efficiency: ${vitalMap.efficiency?.value}%` : '';
        return result;
      case 'Calories':
        return `${vitalMap.calories?.value} Calories`;

      default:
        return `${vitalMap ? JSON.stringify(vitalMap?.value) : ''}`;
    }
  }

  private getMaxVital(vitals: VitalRecord[]) {
    const result = {};
    const measures = this.buildMeasureMap(vitals);
    Object.keys(measures).forEach((key) => {
      result[key] = measures[key].sort((vitalMapA, vitalMapB) => vitalMapB.value - vitalMapA.value)[0];
    });
    return result;
  }

  private calculateAvg(vitals: VitalRecord[]) {
    const result = {};
    const measures = this.buildMeasureMap(vitals);
    Object.keys(measures).forEach((key) => {
      const totalSum = measures[key].reduce((a, b) => a + b.value, 0);
      const avg = totalSum / measures[key].length;
      result[key] = totalSum % measures[key].length === 0 ? avg.toString() : avg.toFixed(3);
    });
    return result;
  }

  private buildMeasureMap(vitals: VitalRecord[]) {
    const result = {};
    if (vitals && vitals.length > 0) {
      const measures = this.measureService.returnValueTypes(vitals[0].measure_type);
      measures.params.forEach((param) => {
        const vitalsByParam = vitals.map((vital) => (vital.value ? { value: vital.value[param], vital } : { value: vital.value1, vital }));
        result[param] = vitalsByParam;
      });
    }
    return result;
  }

  private buildVitalMap(vitals: VitalRecord[]) {
    const vitalsMap = new Map<string, VitalRecord[]>();

    vitals.forEach((vital) => {
      if (vitalsMap.has(vital.measure_type)) {
        vitalsMap.set(vital.measure_type, [...vitalsMap.get(vital.measure_type), vital]);
      } else {
        vitalsMap.set(vital.measure_type, [vital]);
      }
    });
    return vitalsMap;
  }

  private async getReportDataFromOptions(reportObject: ReportModel) {
    const { showNotes, showVitals, showAlerts } = reportObject;
    let notes = [];
    let vitals: VitalRecord[] = [];
    let alerts = [];

    if (showNotes) {
      notes = await this.getClinicalAssetments(reportObject.startDate, reportObject.endDate, reportObject.uid);
    }
    if (showVitals) {
      vitals = await this.getVitals(reportObject.startDate, reportObject.endDate, reportObject.uid);
    }
    if (showAlerts) {
      alerts = await this.getAlerts(reportObject.startDate, reportObject.endDate, reportObject.uid);
    }
    return { alerts, notes, vitals };
  }

  private getClinicalAssetments(startDate: Date, endDate: Date, patientId: string): Promise<any> {
    return this.fs
      .colWithIds$(`clinical_assessments`, (ref) =>
        ref.where('associations.uid', '==', patientId).where('date', '>=', startDate).where('date', '<=', endDate).orderBy('date', 'desc')
      )
      .pipe(take(1))
      .toPromise();
  }

  private getVitals(startDate: Date, endDate: Date, patientId: string, measure_type?: string): Promise<VitalRecord[]> {
    return this.fs
      .colWithIds$('vitals', (ref) => {
        const query = ref
          .where('user_id', '==', patientId)
          .where('invalid', '==', false)
          .where('measureDate', '>=', startDate)
          .where('measureDate', '<=', endDate)
          .orderBy('measureDate', 'desc');
        return measure_type ? query.where('measure_type', '==', measure_type) : query;
      })
      .pipe(take(1))
      .toPromise();
  }

  private getAlerts(startDate: Date, endDate: Date, patientId: string) {
    return this.fs
      .colWithIds$('vital_flags', (ref) =>
        ref
          .where('patient_id', '==', patientId)
          .where('alert_date', '>=', startDate)
          .where('alert_date', '<=', endDate)
          .where('expired', '==', false)
          .where('resolved', '==', false)
          .where('invalid', '==', false)
          .orderBy('alert_date', 'desc')
      )
      .pipe(take(1))
      .toPromise();
  }

  private transformVitalsDataToTable(vitals: any[]) {
    return vitals.map((vital) => {
      const a1c = vital?.value && vital.value?.a1c ? vital.value?.a1c?.toFixed(3) : 'N/A';

      let measure;
      if (vital.measure_type === VitalFlagCategory.BLOOD_PRESSURE) {
        measure = vital.value ? `${vital.value.sbp}/${vital.value.dbp} @ ${vital.value.pulse_bpm} ${vital.units}` : `${vital.value1}/${vital.value2} ${vital.units}`;
      } else if (vital.measure_type === VitalFlagCategory.BLOOD_GLUCOSE) {
        measure = vital.value ? `${vital.value.mgdl} ${vital.units} / A1C ${a1c}` : `${vital.value1} ${vital.units} / A1C ${a1c}`;
      } else if (vital.measure_type === VitalFlagCategory.WEIGHT) {
        measure = vital.value ? `${vital.value.lbs} LBS` : `${vital.value1} ${vital.units}`;
      } else {
        measure = `${vital?.value1} ${vital.units}`;
      }
      return [vital.measure_type, vital.source, measure, this.formatDate(vital.measureDate), vital.comment];
    });
  }

  private async buildContentToPdfWithChart(image: string, startDate: Date, endDate: Date, vitalTable) {
    const patientName = `${this.patientService.currentPatientService.firstName} ${this.patientService.currentPatientService.lastName}`;

    const docDefinition = {
      info: {
        title: patientName,
        author: `Welby`,
      },
      pageOrientation: 'landscape',
      content: [
        { image: 'logo', width: 50 },
        { text: `${patientName} - Patient Report`, style: 'header' },
        `From ${this.formatDate(firebase.firestore.Timestamp.fromDate(startDate), 'MMM Do YYYY')} - ${this.formatDate(
          firebase.firestore.Timestamp.fromDate(endDate),
          'MMM Do YYYY'
        )}`,

        { text: 'Vitals', style: 'subheader' },
        { image: 'chart', fit: [500, 500] },
        vitalTable.length > 1 ? { text: 'Vitals', style: 'subheader' } : '',
        vitalTable
          ? vitalTable.length > 1
            ? { style: 'timesTable', table: { widths: ['*', '*', 150, '*', '*'], body: vitalTable } }
            : { style: 'timesTable', table: { widths: ['*', '*', '*', '*', '*'], body: [...vitalTable, ['No Data', '', '', '']] } }
          : '',
      ],
      images: {
        logo: await this.dataService.getBase64ImageFromURL(this.logoUrl),
        chart: image,
      },
      styles: {
        header: { fontSize: 18, bold: true, margin: [0, 20, 0, 5] },
        subheader: { fontSize: 16, bold: true, margin: [0, 10, 0, 5] },
        summaryTable: { margin: [0, 5, 0, 15] },
        timeTable: { margin: [0, 0, 0, 0] },
        tableHeader: { bold: true, fontSize: 13, color: 'black' },
      },
    };
    return docDefinition;
  }
}
