<form class="search-patient">
  <mat-form-field class="search-patient-field" appearance="{{ searchModel?.appearance }}" floatLabel="never">
    <mat-label *ngIf="searchModel.showLabel">{{ searchModel.label }}</mat-label>
    <mat-icon matSuffix matTooltip="Search patients by firstName, lastName, email, phone, EMR_ID, provider and client name">search</mat-icon>
    <input
      class="search-patient-input"
      type="text"
      placeholder="{{ searchModel?.placeholder || 'Search Patient' }}"
      aria-describedby="{{ searchModel?.arialabel || 'Search Patient' }}"
      matInput
      [formControl]="searchInput"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
      <mat-option *ngFor="let patient of filteredOptions$ | async" [value]="patient" (click)="emitSelectedPatient(patient)">
        <img
          class="search-patient-img"
          aria-hidden
          alt="{{ patient.firstName }} {{ patient.lastName }}"
          [src]="patient.profileImageURL"
          height="25"
          (error)="handleImageError($event)"
        />
        <span>{{ patient.firstName }} {{ patient.lastName }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
