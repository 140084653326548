import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AiMessagesService } from 'src/app/services/ai/ai-messages.service';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { SmsService } from 'src/app/services/communications/sms.service';
import { LanguageService } from 'src/app/services/language/language.service';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';

export interface MessageCategory {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit, OnChanges {
  @ViewChild('inputFile') inputFile: ElementRef;
  @Input() message = '';
  @Output() smsSent = new EventEmitter<boolean>();
  contentVisible = true;
  form: FormGroup;
  loading = false;
  loadingAIMessage = false;
  noAIerrors = false;
  aiMessage: boolean = false;
  languageModel = { label: 'Translate To', showLabel: true, appearance: 'outline', showOnlyActive: false };
  categories: MessageCategory[] = [
    { value: 'welby', viewValue: 'Secure Message' },
    { value: 'sms', viewValue: 'SMS Message' },
  ];
  attachments = [];
  attachmentsGreater2MBCounter = 0;
  provider: User;
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    private fs: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private smsService: SmsService,
    private aiMessagesService: AiMessagesService,
    private patientService: PatientService,
    private languageService: LanguageService,
    private snackService: SnackService,
    private utilsService: UtilsService,
    private authService: NewAuthService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message && this.form) {
      this.form.get('text').setValue(changes.message.currentValue);
    }
  }

  ngOnInit(): void {
    this.provider = {
      ...this.authService.user,
      twilio_line: Array.isArray(this.authService.user.twilio_line) ? this.authService.user.twilio_line : [this.authService.user.twilio_line],
    };
    this.initializeForm();
    if (this.data.sms === false) {
      this.categories = [{ value: 'welby', viewValue: 'Secure Message' }];
    } else if (this.data.external) {
      this.categories = [{ value: 'sms', viewValue: 'SMS Message' }];
      this.form.get('number').setValidators(Validators.compose([Validators.required, phoneNumberValidator]));
    }

    this.authService.getAiMessageValue(this.authService.user.user_id).subscribe((value) => {
      this.aiMessage = value;
      if (this.data.useAI2GenerateSMS && this.aiMessage) {
        this.getAIMessage();
      } else {
        this.clearAISurveyForm();
      }
    });
  }

  onToggleChange(event: any): void {
    this.aiMessage = event.checked;
    if (this.aiMessage) {
      this.getAIMessage();
    } else {
      this.clearAISurveyForm();
    }
  }

  initializeForm(): void {
    this.form = this.fs.group({
      text: [this.message ? this.message : this.data?.text, Validators.required],
      type: [this.data.type ?? '', Validators.required],
      // eslint-disable-next-line id-blacklist
      number: [this.data.number ?? ''],
      language: [this.patientService?.currentPatientService?.language ?? ''],
      attachments: [],
      twilioNumber: [this.provider?.twilio_line[0], Validators.required],
    });
  }

  translateText(event: { name: string; active?: boolean }): void {
    this.loading = true;
    this.languageService.translateText(this.form.get('text').value, event.name).subscribe(
      (res) => {
        this.form.get('text').setValue(res.translatedText);
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  clearAISurveyForm(): void {
    this.form.get('text').setValue('');
  }

  async sendData(): Promise<void> {
    this.loading = true;
    const form = this.form.getRawValue();
    this.data.text = form.text;
    this.data.twilio_line = form.twilioNumber;
    if (form.number) {
      // eslint-disable-next-line id-blacklist
      this.data.number = form.number.split('-').reduce((curr: string, prev: string) => curr + prev);
    }
    this.data.type = form.type;
    if (this.data.external) {
      this.dialogRef.close(this.data);
    } else {
      const smsWasSent = await this.smsService.handleSMSMessage({ ...this.data, attachments: this.attachments, numberFilesExceed2MB: this.attachmentsGreater2MBCounter });
      this.loading = false;
      this.smsSent.emit(smsWasSent);
      if (this.data.useAI2GenerateSMS) {
      }
      this.dialogRef.close();
    }
  }

  getAIMessage(): void {
    this.loadingAIMessage = true;
    this.aiMessagesService
      .getAIMessage(this.patientService.currentPatientService.provider_id, this.patientService.currentPatientService.user_id)
      .pipe(
        switchMap((res) => {
          if (res.error) {
            this.clearAISurveyForm();
            this.data.useAI2GenerateSMS = false;
            this.snackService.genericSnackBar(res.error, ['warn-snackbar'], 6000);
            return of({ error: res.error });
          } else {
            this.noAIerrors = true;
            return this.form.get('language').value ? this.languageService.translateText(res.message, this.form.get('language').value) : of({ translatedText: res.message });
          }
        })
      )
      .subscribe((res) => {
        if (res.translatedText) {
          this.noAIerrors = true;
          this.form.get('text').setValue(res.translatedText);
        }
        this.loadingAIMessage = false;
      });
  }

  async onDocumentFileSelected(event): Promise<void> {
    event.preventDefault();
    const files = await Promise.all(
      Array.from(event.target.files).map(async (file: File) => {
        if (file.size > 2000000) {
          this.snackService.genericSnackBar('File size exceed 2MB. We added a link into SMS to download it.', ['warn-snackbar'], 8000);
          this.attachmentsGreater2MBCounter++;
        }
        const fileBase64 = await this.utilsService.getBase64FromFile(file);
        return { fileName: file.name, contentType: file.type, content: fileBase64 };
      })
    );
    this.attachments = files ? [...files, ...this.attachments] : [];
  }

  toggleSize() {
    if (!this.contentVisible) {
      this.maximizeDialog();
    } else {
      this.minimizeDialog();
    }
  }

  minimizeDialog() {
    this.contentVisible = false;
    this.dialogRef.updatePosition({ bottom: '0', right: '0' });
    this.removeBackDrop();
  }

  maximizeDialog() {
    this.contentVisible = true;
    this.dialogRef.updatePosition();
    this.addBackDrop();
  }

  removeBackDrop(): void {
    const backdrop = document.querySelector('.cdk-overlay-backdrop');
    backdrop.classList.add('cdk-overlay-backdrop-hide');
  }

  addBackDrop(): void {
    const backdrop = document.querySelector('.cdk-overlay-backdrop');
    backdrop.classList.remove('cdk-overlay-backdrop-hide');
  }
}
