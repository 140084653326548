import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, forkJoin } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { TimeCategory } from 'src/app/dialogs/clinical-time-dialog.component';
import { ClinicalTime } from 'src/app/models/clinical.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { ClinicalNoteCacheService } from 'src/app/services/clinical-note-cache.service';
import { DataService } from 'src/app/services/data.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { MeasureSummaryService } from 'src/app/services/measures/measure-summary.service';
import { PotentialWin } from 'src/app/services/models/quick-wins.model';
import { PatientTimerService } from 'src/app/services/patient-timer.service';
import { PatientService } from 'src/app/services/patient.service';
import { ProgramService } from 'src/app/services/programs/program.service';
import { SnackService } from 'src/app/services/snack.service';
import { environment } from 'src/environments/environment';
import { AreYouSureModalComponent } from '../../are-you-sure-modal/are-you-sure-modal.component';
import { atLeastOneFieldValidator } from '../../validators/one-field-filled-at-least';

@Component({
  selector: 'app-clinical-time-save-form',
  templateUrl: './clinical-time-save-form.component.html',
  styleUrls: ['./clinical-time-save-form.component.scss'],
})
export class ClinicalTimeSaveFormComponent implements OnInit, OnDestroy {
  @Input() data;
  @Output() closeDialog = new EventEmitter<boolean>();
  form: FormGroup;
  delay = 1000;
  categories: TimeCategory[] = [];
  programs: any[] = [];
  loading = false;
  currentMonthlyTime: any;
  currentTimes = {};
  welbyConf: { rpmConf: any; ccmConf: any };
  potentialWins: PotentialWin[] = [];
  unsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private patientService: PatientService,
    private auth: NewAuthService,
    private snackBar: SnackService,
    private dataService: DataService,
    private activePrograms: ProgramService,
    private timeService: PatientTimerService,
    private dialog: MatDialog,
    private cacheNote: ClinicalNoteCacheService,
    private firebaseService: FirestoreService,
    private measureService: MeasureSummaryService
  ) {}

  ngOnInit() {
    this.getActivePrograms();
    this.getCurrentMonthlyTime();
    this.categories = this.dataService.categories;
    this.form = this.fb.group({
      times: this.fb.array([], Validators.required),
      exclude: [false, []],
      contact: [false, []],
      require_provider_intervention: [false, []],
      device_troubleshooting: [false, []],
    });
    this.addTime(this.data?.time?.mins?.toString()?.padStart(2, '0'), this.data?.time?.secs?.toString()?.padStart(2, '0'), this.data.time.categories);
    if (this.data.id) {
      this.currentTimes[this.data.id] = this.data.time; // If the component is in edit mode, we need to store the current time to check if the time is out of the limit
    }
    this.getWelbyConf();
    this.timeService
      .messageBillingGoals(this.auth.user.user_id, this.patientService?.currentPatientServiceID)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((potentialWins) => !!potentialWins && potentialWins.length > 0)
      )
      .subscribe((patient: PotentialWin[]) => {
        this.potentialWins = patient?.map((qw) => ({
          ...qw,
          visible: true,
        }));
      });
  }

  getWelbyConf(): void {
    this.measureService.getWelbyConf().subscribe((conf) => {
      this.welbyConf = conf;
    });
  }

  getCurrentMonthlyTime(): void {
    this.patientService.getCurrentMonthlyTime(this.patientService.currentPatientServiceID).subscribe((time: { status: string; data: any }) => {
      this.currentMonthlyTime = time.data;
    });
  }

  getActivePrograms() {
    this.activePrograms
      .getPrograms()
      .pipe(take(1))
      .subscribe((programs) => {
        this.programs = programs.sort((a, b) => a.sortIndex - b.sortIndex);
      });
  }

  get times() {
    return this.form.controls['times'] as FormArray;
  }

  closeMessage(index: number): void {
    if (this.potentialWins[index]) {
      this.potentialWins[index].visible = false;
    }
  }

  addTime(mins?, secs?, categories?: any) {
    console.log('cat', categories);
    const timeForm = this.fb.group({
      mins: [mins ?? '0', [Validators.compose([Validators.required, Validators.pattern(/^\d{1,3}$/)])]],
      secs: [secs ?? '0', [Validators.compose([Validators.required, Validators.pattern(/^([0-9]?\d)$/)])]],
      categories: this.fb.group(
        {
          rpms: [categories?.rpms ?? false, []],
          rpmc: [categories?.rpmc ?? false, []],
          ccm: [categories?.ccm ?? false, []],
          tcm: [categories?.tcm ?? false, []],
          chi: [categories?.chi ?? false, []],
          pin: [categories?.pin ?? false, []],
          bhi: [categories?.bhi ?? false, []],
          g0511: [categories?.g0511 ?? false, []],
          rtm: [categories?.rtm ?? false, []],
          pcm: [categories?.pcm ?? false, []],
          econsult: [categories?.econsult ?? false, []],
          visit: [categories?.visit ?? false, []],
        },
        { validators: atLeastOneFieldValidator }
      ),
    });
    (this.form.get('times') as FormArray).push(timeForm);
  }

  deleteTime(index) {
    (this.form.get('times') as FormArray).removeAt(index);
  }

  saveTime(): void {
    this.loading = true;
    const loadDate = this.firebaseService.timestamp;
    const times = this.times.getRawValue();
    const calls = [];
    const timesForm = [];
    const outLimitTimes = [];

    times.forEach((time) => {
      const loadTime = Number(time.mins) + Number(time.secs) / this.timeService.getOneMinuteInSeconds();
      const newTime: ClinicalTime = {
        minutes: loadTime,
        categories: {
          rpm: (time.categories.rpms || time.categories.rpmc) ?? false,
          rpms: time.categories.rpms ?? false,
          rpmc: time.categories.rpmc ?? false,
          ccm: time.categories.ccm ?? false,
          tcm: time.categories.tcm ?? false,
          pcm: time.categories.pcm ?? false,
          bhi: time.categories.bhi ?? false,
          pin: time.categories.pin ?? false,
          chi: time.categories.chi ?? false,
          rtm: time.categories.rtm ?? false,
          econsult: time.categories.econsult ?? false,
          visit: time.categories.visit ?? false,
        },
        category: this.getCategoryForBilling(time.categories),
        timeStamp: this.data.isNew ? loadDate : this.data.time.timeStamp,
        last_update: new Date(),
        user_id: this.auth.user.user_id,
        patient_id: this.patientService?.currentPatientServiceID,
        excluded: this.form.get('exclude').value ?? false,
        contact: this.form.get('contact').value ?? false,
        require_provider_intervention: this.form.get('require_provider_intervention').value ?? false,
        device_troubleshooting: this.form.get('device_troubleshooting').value ?? false,
        is_billable: this.welbyStaffTrackedTime(),
      };
      // If is editing time, we need to check if the time is out of the limit
      const prevMinutesValue = this.data.id
        ? Number(this.currentTimes[this.data.id]?.mins) + Number(this.currentTimes[this.data.id]?.secs) / this.timeService.getOneMinuteInSeconds()
        : 0;
      if (newTime.category === 'rpm' || newTime.category === 'rpms' || newTime.category === 'rpmc') {
        if (this.currentMonthlyTime.rpmTotal + newTime.minutes - prevMinutesValue > this.welbyConf?.rpmConf?.limit) {
          outLimitTimes.push(newTime);
        }
      }
      if (newTime.category === 'ccm') {
        if (this.currentMonthlyTime.ccmTotal + newTime.minutes - prevMinutesValue > this.welbyConf?.ccmConf?.limit) {
          outLimitTimes.push(newTime);
        }
      }
      timesForm.push(newTime);
      calls.push(this.patientService.handleClinicalTime(newTime, this.data.isNew, this.data.id));
    });

    if (outLimitTimes.length > 0) {
      this.loading = false;
      let message = '';
      if (outLimitTimes.some((time) => time.category === 'rpm' || time.category === 'rpms' || time.category === 'rpmc')) {
        message = 'This patient has exceeded the monthly limit for RPM category, Are you sure to proceed? This is not a billable service';
      } else {
        message = 'This patient has exceeded the monthly limit for CCM category, Are you sure to proceed? This is not a billable service';
      }
      const snackRef = this.snackBar.genericSnackBar(message, ['warn-snackbar'], 8000, 'Yes, proceed');
      snackRef.onAction().subscribe((resp) => {
        this.saveArrayTimes(calls, timesForm);
      });
    } else {
      this.saveArrayTimes(calls, timesForm);
    }
  }

  /**
   * Saves the array of times using the provided calls and updates the UI accordingly.
   *
   * @param calls - An array of observables representing the save calls.
   * @param timesForm - An array of ClinicalTime objects to be saved.
   */
  saveArrayTimes(calls: Observable<any>[], timesForm: ClinicalTime[]): void {
    forkJoin(calls).subscribe(() => {
      this.snackBar.genericSnackBar('Time saved', ['success-snackbar']);
      this.loading = false;
      this.closeDialog.emit(true);
      this.openClinicalDialogIfNoNoteWasAdded(timesForm);
    });
  }

  getCategoryForBilling(categories: any): string {
    if (categories.rpms) {
      return 'rpms';
    } else if (categories.rpmc) {
      return 'rpmc';
    } else if (categories.bhi) {
      return 'bhi';
    } else {
      return 'ccm'; // by now, all the other categories are CCM (Just for billing purposes)
    }
  }

  async openClinicalDialogIfNoNoteWasAdded(timesForm: ClinicalTime[]): Promise<void> {
    const todayNotes = await this.patientService.getClinicalTodayAssessments(this.patientService.currentPatientServiceID);
    if (todayNotes.length === 0) {
      const dialogRef = this.dialog.open(AreYouSureModalComponent, {
        data: {
          title: 'Add Clinical Note',
          body: `<p>Do you want to save a clinical note for today?</p>`,
          hideCancelBtn: false,
          cancelButton: 'Cancel',
          confirmButton: 'Create Note',
        },
        width: '500px',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const note = {
            assessor: this.auth.user.user_id,
            content: timesForm
              .map((time) => (time.category === 'rpm' ? 'Reviewed patient vitals, no interventions needed.' : 'Reviewed patient care plan, no interventions needed.'))
              .join(' '),
          };
          this.cacheNote.saveClinicalNoteInCache(note);
          this.patientService.toggleNoteView();
        }
      });
    }
  }

  cancel(): void {
    this.closeDialog.emit(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Verify if the client responsible id is from Welby Staff
   */
  private welbyStaffTrackedTime(): boolean {
    const client_responsible_id = this.auth.user.client_responsible_id;
    return client_responsible_id === environment.welby_admin_client_id;
  }
}
