import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { AlertContact } from 'src/app/models/patient.model';
import { DataService } from 'src/app/services/data.service';
import { DefaultVitalFlagsComponent } from '../dialogs/default-vital-flags/default-vital-flags.component';
import { AlertContactDialogComponent } from '../dialogs/patient/alert-contact-dialog/alert-contact-dialog.component';
import { CareplanProfileDialogComponent } from '../dialogs/patient/careplan-profile-dialog/careplan-profile-dialog.component';
import { CareteamDialogComponent } from '../dialogs/patient/careteam-dialog/careteam-dialog.component';
import { EMRSearchComponent } from '../dialogs/patient/emr-search/emr-search.component';
import { InsuranceDialogComponent } from '../dialogs/patient/insurance-dialog/insurance-dialog.component';
import { PatientDemographicsDialogComponent } from '../dialogs/patient/patient-demographics-dialog/patient-demographics-dialog.component';
import { ClientContactDialogComponent } from '../dialogs/practice/client-contact-dialog/client-contact-dialog.component';
import { ClientContact } from '../models/client.model';
import { PatientFlagCriteria, VitalFlagTier } from '../models/vitals/vital-flag.model';
import { FhirMedicationDetailComponent } from '../patient/careplan/fhir-medications/dialogs/fhir-medication-detail/fhir-medication-detail.component';
import { VitalFlagWizardDialogComponent } from '../patient/rpm/vital-flag-criteria/dialogs/vital-flag-wizard-dialog/vital-flag-wizard-dialog.component';
import { NppesSearchDialogComponent } from '../patient/team/care-team-ancillary/dialogs/nppes-search-dialog/nppes-search-dialog.component';
import { AreYouSureModalComponent } from '../shared/are-you-sure-modal/are-you-sure-modal.component';
import { EmailPatientDialogComponent } from '../shared/dialogs/email-patient-dialog/email-patient-dialog.component';
import { VitalEntryDialogComponent } from './../dialogs/patient/vital-entry-dialog/vital-entry-dialog.component';
import { VitalFlagDialogComponent } from './../dialogs/patient/vital-flag-dialog/vital-flag-dialog.component';
import { CareTeamMember } from './../models/care-team.model';
import { CarePlanProfileQuestion } from './../models/carereplan-model';
import { PatientService } from './patient.service';
import { PracticeService } from './practice.service';
import { SnackService } from './snack.service';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  constructor(
    public dialog: MatDialog,
    private dataService: DataService,
    private patientService: PatientService,
    private practiceService: PracticeService,
    private db: AngularFirestore,
    private breakpointObserver: BreakpointObserver,
    private snackService: SnackService
  ) {}

  openCarePlanProfileDialog(isNew: boolean, profile?: CarePlanProfileQuestion[]): void {
    const dialogRef = this.dialog.open(CareplanProfileDialogComponent, {
      width: '750px',
      maxWidth: '100vw',
      data: profile ? { profile, isNew } : { profile: [], isNew },
    });

    const subscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const updatedObject = {};
        const uid = this.patientService.currentPatientServiceID;

        result.profile.forEach((q) => {
          updatedObject[q.question_id] = q;
        });

        this.db.collection('patient_profiles').doc(uid).set(updatedObject, { merge: true });
      }
      subscription.unsubscribe();
    });
  }

  openCareTeamDialog(isNew: boolean, teamMember?: CareTeamMember): void {
    if (!isNew && teamMember.reports === undefined) {
      // for backwards compatability to update old records
      teamMember.reports = {
        rpm: false,
        ccm: false,
        method: 'none',
      };
    }
    const dialogRef = this.dialog.open(CareteamDialogComponent, {
      width: '600px',
      maxWidth: '100vw',
      disableClose: true,
      data: teamMember
        ? { teamMember: { ...teamMember }, isNew }
        : {
            teamMember: {
              active: true,
              first_name: '',
              last_name: '',
              degree: '',
              organization: '',
              profile_image_url: '',
              provider_id: '',
              speciality: '',
              type: 'Other',
              phone: '',
              email: '',
              fax: '',
              reports: {
                method: 'none',
                rpm: false,
                ccm: false,
              },
            },
            isNew,
          },
    });

    const subscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const updatedMember: CareTeamMember = {
          active: true,
          comments: result.comments,
          first_name: result.first_name,
          last_name: result.last_name,
          degree: result.degree,
          organization: result.organization ?? 'N/A',
          profile_image_url:
            'https://firebasestorage.googleapis.com/v0/b/welby-dev.appspot.com/o/profile_images%2Fdoctor_placeholder.png?alt=media&token=865e5202-8b04-47d5-9e99-2516ac30af81',
          provider_id: 'TEST',
          speciality: result.speciality ?? 'N/A',
          type: result.type,
          phone: result.phone ? `+1${result.phone.replace(/-/g, '')}` : '',
          email: result.email ?? 'N/A',
          fax: result.fax ?? 'N/A',
          reports: {
            method: result.method,
            rpm: result.rpm,
            ccm: result.ccm,
          },
        };

        if (isNew) {
          await this.db
            .collection('users')
            .doc(this.patientService.currentPatientServiceID)
            .collection('my_care_team')
            .add(updatedMember)
            .catch((error) => {
              console.error(error);
              this.snackService.genericSnackBar(`An error occurred while team member creation`, ['error-snackbar']);
            });
          this.snackService.genericSnackBar('Team member added successfully', ['success-snackbar']);
        } else {
          await this.db
            .collection('users')
            .doc(this.patientService.currentPatientServiceID)
            .collection('my_care_team')
            .doc(teamMember.id)
            .set(updatedMember)
            .catch((error) => {
              console.error(error);
              this.snackService.genericSnackBar(`An error occurred while team member update`, ['error-snackbar']);
            });
          this.snackService.genericSnackBar('Team member updated successfully', ['success-snackbar']);
        }
      }
      subscription.unsubscribe();
    });
  }

  // CLINICAL ALERTS
  openAlertContactDialog(isNew: boolean, contactData?: AlertContact): void {
    const dialogRef = this.dialog.open(AlertContactDialogComponent, {
      width: '400px',
      data: contactData ? { contactData: { ...contactData }, isNew } : { contactData: { data: '', type: 'provider' }, isNew },
    });

    dialogRef.afterClosed().subscribe((result) => {
      const uid = this.patientService.currentPatientServiceID;
      if (result) {
        const updatedContact: AlertContact = {
          data: result.contactData.data,
          type: result.contactData.type,
        };

        if (result.isNew) {
          return this.db
            .collection('users')
            .doc(uid)
            .update({ alert_contacts: firebase.firestore.FieldValue.arrayUnion(updatedContact) });
        } else {
          this.db
            .collection('users')
            .doc(uid)
            .update({ alert_contacts: firebase.firestore.FieldValue.arrayRemove(contactData) });
          return this.db
            .collection('users')
            .doc(uid)
            .update({ alert_contacts: firebase.firestore.FieldValue.arrayUnion(updatedContact) });
        }
      }
    });
  }

  openClientContactDialog(isNew: boolean, clientId: string, contact?: ClientContact): void {
    const dialogRef = this.dialog.open(ClientContactDialogComponent, {
      width: '500px',
      data: contact
        ? // if I passed in a user object, this is updating.
          {
            contact: {
              name: contact.name,
              primary_email: contact.primary_email,
              primary_phone: contact.primary_phone,
              roles: {
                admin: contact.roles ? contact.roles.admin : false,
                billing: contact.roles ? contact.roles.billing : false,
                clinical: contact.roles ? contact.roles.clinical : false,
              },
            },
            contact_id: contact.id,
            clientId,
            isNew: false,
          }
        : // otherwise these set some default values.
          {
            contact: {
              roles: {
                admin: false,
                billing: false,
                clinical: false,
              },
            },
            clientId,
            contact_id: null,
            isNew: true,
          },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const updatedContact = {
          name: result.contact.name,
          primary_phone: result.contact.primary_phone,
          primary_email: result.contact.primary_email,
          roles: result.contact.roles,
          active: true,
          uid: 'tbd',
        };
        this.practiceService.handleClientContact(result.isNew, updatedContact, result.clientId, result.contact_id);
      }
    });
  }

  // OTHER
  openCoverageDialog(): void {
    const dialogRef = this.dialog.open(InsuranceDialogComponent, {
      width: '600px',
      data: {},
    });
  }

  openVitalFlagDialog(isNew: boolean, flag?: PatientFlagCriteria) {
    const dialogRef = this.dialog.open(VitalFlagDialogComponent, {
      width: '600px',
      data: flag
        ? { flag: { ...flag }, isNew }
        : {
            flag: {
              active: true, // whether the flag is active
              type: 'Weight',
              alert_patient: false, // whether an alert to patient should be sent
              alert_provider: true, // whether an alert to provider should be sent
              tier: VitalFlagTier.TIER_2,
              comparitor: 'measures', // whether you are comparing to a number of measures, a number of days or a fixed amount
              comparitor_type: 'percent', // whether the vital is either a pecent higher or a straight number higher
              count: String(2), // either the number of measures or the number of days to include in the average
              offset_amount: String(10), // the number of +/- when comparing a value
              offset_type: '+', // whether to add or subtract the value
              operator: '<', // comparing greater than, less than, etc
              secondary: false, // whether this need to compare the primary or secondary
            },
            isNew,
          },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const flagData: PatientFlagCriteria = {
          active: result.flag.active,
          type: result.flag.type,
          alert_patient: result.flag.alert_patient,
          alert_provider: result.flag.alert_provider,
          tier: result.flag.tier,
          comparitor: result.flag.comparitor,
          comparitor_type: result.flag.comparitor_type,
          count: Number(result.flag.count),
          notes: 'N/A',
          offset_amount: Number(result.flag.offset_amount),
          offset_type: result.flag.offset_type,
          operator: result.flag.operator,
          priority: 1,
          secondary: result.flag.secondary,
        };

        const flagRef = this.db.collection('vital_flag_override');
        if (result.isNew) {
          flagRef
            .add(flagData)
            .then((docRef) => {
              this.snackService.genericSnackBar('Vital flag added successfully', ['success-snackbar']);
            })
            .catch((error) => {
              console.error(error);
              this.snackService.genericSnackBar('An error occurred while adding vital flag', ['error-snackbar']);
            });
        } else {
          flagRef
            .doc(flag.id)
            .set(flagData, { merge: true })
            .then(() => {
              this.snackService.genericSnackBar('Vital flag updated successfully', ['success-snackbar']);
            })
            .catch((error) => {
              console.error(error);
              this.snackService.genericSnackBar('An error occurred while updating vital flag', ['error-snackbar']);
            });
        }
      }
    });
  }

  openVitalDefaultDialog(): void {
    const dialogRef = this.dialog.open(DefaultVitalFlagsComponent, { width: '750px', maxWidth: '100vw' });

    const subscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      subscription.unsubscribe();
    });
  }

  openVitalFlagWizardDialog(): void {
    const dialogRef = this.dialog.open(VitalFlagWizardDialogComponent, { width: '800px', maxWidth: '100vw' });

    const subscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      subscription.unsubscribe();
    });
  }

  openNPPESSearchDialog(): void {
    const dialogRef = this.dialog.open(NppesSearchDialogComponent, { width: '750px', maxWidth: '100vw' });

    const subscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      subscription.unsubscribe();
    });
  }

  openManualVitalDialog(): MatDialogRef<any> {
    const dialogRef = this.dialog.open(VitalEntryDialogComponent, {
      width: '350px',
      maxWidth: '100vw',
      data: {
        device: '',
        measure_type: 'Blood Pressure',
      },
    });

    this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    return dialogRef;
  }

  openSendEmailDialog(): MatDialogRef<any> {
    const sendEmailDialogRef = this.dialog.open(EmailPatientDialogComponent, {
      width: '600px',
      data: {
        fromDashboard: true,
      },
    });
    return sendEmailDialogRef;
  }

  openSearchEMRPatientDialog(): MatDialogRef<any> {
    const searchEMRPatientDialogRef = this.dialog.open(EMRSearchComponent, {
      width: '650px',
      panelClass: 'welby-modal-scroll',
    });
    return searchEMRPatientDialogRef;
  }

  openNewPatientDialog(data?: any): MatDialogRef<any> {
    const patientDialogRef = this.dialog.open(PatientDemographicsDialogComponent, {
      width: '600px',
      data: data ?? {},
      panelClass: 'welby-modal-scroll',
    });
    return patientDialogRef;
  }

  openEmailNotVerfiedDialog(): MatDialogRef<any> {
    const dialog = this.dialog.open(AreYouSureModalComponent, {
      width: '500px',
      disableClose: true,
      data: {
        title: 'Email is not verified',
        body: `In order to use Welby, you must verify your email address. Please check your email for a verification link. 
        If you did not receive an email, please click the button below to resend the verification email.`,
        confirmButton: 'Resend Verification Email',
        cancelButton: 'Log out',
      },
    });
    return dialog;
  }

  openDeleteCareTeamMemberDialog(member: CareTeamMember): MatDialogRef<any> {
    const modal = this.dialog.open(AreYouSureModalComponent, {
      width: '500px',
      data: {
        title: `Delete care team member`,
        body: `Are you sure you want to delete ${member.first_name} ${member.last_name}?. This action cannot be undone.`,
        hideCancelBtn: false,
        cancelButton: 'Cancel',
        confirmButton: 'Delete it!',
      },
    });
    return modal;
  }

  openMedicationDetailDialog(medication?): MatDialogRef<any> {
    const medicationDetailDialogRef = this.dialog.open(FhirMedicationDetailComponent, {
      width: '650px',
      panelClass: 'welby-modal-scroll',
      data: medication,
    });
    return medicationDetailDialogRef;
  }
}
