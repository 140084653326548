import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'patientName',
})
export class PatientNamePipe implements PipeTransform {
  transform(firstname: string, lastname: string, lastNameMode = false): unknown {
    if (!firstname && !lastname) {
      return '';
    }
    firstname = firstname
      .split(' ')
      .filter((names) => names !== '')
      .map((n, index) => (index === 0 ? n.charAt(0).toUpperCase() + n.slice(1).toLowerCase() : this.validateSpecialCharacters(n).charAt(0).toUpperCase() + '.'))
      .join(' ');
    lastname = lastname
      .split(' ')
      .filter((names) => names !== '')
      .map((n, index) => (index === 0 ? n.charAt(0).toUpperCase() + n.slice(1).toLowerCase() : this.validateSpecialCharacters(n).charAt(0).toUpperCase() + '.'))
      .join(' ');

    return lastNameMode ? `${lastname}, ${firstname}` : `${firstname} ${lastname}`;
  }

  validateSpecialCharacters(name: string): string {
    return name.startsWith("'") || name.startsWith('(') || name.startsWith('*') ? name.slice(1) : name;
  }
}
