<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <h1 mat-dialog-title>Add A New User To Provider Account</h1>
  <div mat-dialog-content>
    <mat-form-field style="width: 49%; margin-right: 2%">
      <input placeholder="First Name" matInput formControlName="firstName" [(ngModel)]="data.provider.firstName" required />
    </mat-form-field>

    <mat-form-field style="width: 49%">
      <input placeholder="Last Name" matInput formControlName="lastName" [(ngModel)]="data.provider.lastName" required />
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <input placeholder="Email Address" matInput formControlName="email" [(ngModel)]="data.provider.email" required />
    </mat-form-field>

    <mat-form-field style="width: 100%">
      <mat-label>Client</mat-label>
      <mat-select formControlName="client_id" [(ngModel)]="data.provider.client" required>
        <mat-option *ngFor="let client of clients" [value]="client">
          {{ client.client_name }}
        </mat-option>
      </mat-select>
      <mat-error>You must assign a client</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="data" cdkFocusInitial>Send Invite Email</button>
  </div>
</form>
