import { Injectable } from '@angular/core';
import { Patient } from 'src/app/models/patient.model';
import { NewAuthService } from '../auth/new-auth-service.service';
import { PatientCommunicationType } from '../models/patient-communication.model';
import { PatientCommunicationService } from '../patient-communication/patient-communication.service';
import { PatientService } from '../patient.service';
import { SnackService } from '../snack.service';
import { TwilioService } from './twilio.service';

@Injectable({
  providedIn: 'root',
})
export class SmsService {
  constructor(
    private patientService: PatientService,
    private auth: NewAuthService,
    private snackService: SnackService,
    private communicationService: PatientCommunicationService,
    private twilio: TwilioService
  ) {}

  async handleSMSMessage(messageBody: any): Promise<boolean> {
    const receiver: Patient = this.patientService.currentPatientService;
    const sender: Patient = {
      ...this.auth.user,
      twilio_line: messageBody?.twilio_line,
    };

    if (messageBody?.type === 'welby') {
      this.patientService.addMessage({
        author_id: this.auth.user.user_id,
        patient_id: this.patientService.currentPatientServiceID,
        client_id: this.patientService.currentPatientService.client_responsible_id,
        compose_date: new Date(),
        text: messageBody.text,
        read: false,
        patient_sent: false,
      });
    } else if (messageBody?.type === 'sms') {
      if (!receiver.sms_contact) {
        this.snackService.genericSnackBar('Patient does not have sms contact', ['error-snackbar'], 5000);
        return false;
      }
      try {
        const communicationRecord = this.communicationService.buildCommunicationRecord('SMS', messageBody.text, receiver, sender, PatientCommunicationType.SMS);
        await this.twilio.sendPatientText(receiver, sender, messageBody?.text, messageBody.attachments, messageBody.numberFilesExceed2MB);
        this.snackService.genericSnackBar('SMS sent successfully', ['success-snackbar'], 3000);
        this.communicationService
          .saveCommunicationRecord(communicationRecord, receiver?.user_id)
          .then(() => {
            this.snackService.genericSnackBar('Saving in communication history', ['success-snackbar'], 3000);
          })
          .catch((error) => {
            this.snackService.genericSnackBar('Error saving communications history', ['error-snackbar'], 4000);
          });
        return true;
      } catch (error) {
        this.snackService.genericSnackBar('Error sending SMS', ['error-snackbar'], 5000);
        return false;
      }
    }
    return false;
  }
}
