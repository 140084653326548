<section class="top-panel">
  <div class="list-container" fxFill fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutGap="5px">
    <section class="left-panel" *ngIf="user">
      <mat-card class="mat-elevation-z4">
        <mat-card-header>
          <mat-card-title *ngIf="user">{{ user?.firstName }} {{ user?.lastName }}</mat-card-title>
          <mat-card-subtitle *ngIf="user">{{ user?.email }}</mat-card-subtitle>
        </mat-card-header>
        <img
          class="user-image"
          onclick="document.getElementById('selectedFile').click();"
          *ngIf="user"
          [src]="user?.profileImageURL"
          alt="{{ user?.firstName }} {{ user?.lastName }}"
        />
        <mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
        <input type="file" id="selectedFile" style="display: none" (change)="onFileSelected($event)" />
        <mat-card-content>
          <mat-list dense>
            <mat-list-item matTooltip="Birthday">
              <mat-icon color="accent" matListIcon>calendar_today</mat-icon>
              <h3 class="user-list-item" matLine>{{ user?.dob | date }}</h3>
            </mat-list-item>

            <mat-list-item matTooltip="SMS contact" *ngIf="user?.sms_contact">
              <mat-icon color="accent" matListIcon>perm_phone_msg</mat-icon>
              <h3 class="user-list-item" matLine>{{ user?.sms_contact }}</h3>
            </mat-list-item>

            <mat-list-item matTooltip="Phone" *ngIf="user?.phone">
              <mat-icon color="accent" matListIcon>phone</mat-icon>
              <h3 class="user-list-item" matLine>{{ user?.phone }}</h3>
            </mat-list-item>

            <ng-container *ngIf="user?.twilio_line?.length > 0">
              <mat-divider></mat-divider>
              <div fxFill fxLayout="row" fxLayoutAlign="center">
                <h2>Twilio lines</h2>
              </div>
              <mat-list-item matTooltip="Twilio line {{ i + 1 }}" *ngFor="let line of user.twilio_line; let i = index">
                <mat-icon color="accent" matListIcon>phone</mat-icon>
                <h3 class="user-list-item" matLine>{{ line }}</h3>
              </mat-list-item>
            </ng-container>

            <mat-list-item matTooltip="Weight" *ngIf="user?.weight_lbs">
              <mat-icon color="accent" fontSet="material-symbols-outlined" matListIcon>weight</mat-icon>
              <h3 class="user-list-item" matLine>Weight: {{ user?.weight_lbs }} lbs</h3>
            </mat-list-item>

            <mat-list-item matTooltip="Pregnancies" *ngIf="user?.pregnancies && user?.pregnancies > 0">
              <mat-icon color="accent" matListIcon>pregnant_woman</mat-icon>
              <h3 class="user-list-item" matLine>{{ user?.pregnancies }}</h3>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </section>
  </div>
</section>
