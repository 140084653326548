import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { take } from 'rxjs/operators';
import { PatientAddress } from 'src/app/models/patient.model';
import { AddressUSPSValidatorService } from 'src/app/services/address-validator/address-uspsvalidator.service';
import { DataService } from 'src/app/services/data.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  @Input()
  address: PatientAddress;
  @Input()
  expandPanel = false;
  @Output()
  toSaveAddress = new EventEmitter<PatientAddress>();
  @Output()
  toDeleteAddress = new EventEmitter<PatientAddress>();
  addressForm: FormGroup;
  states: {
    name: string;
    abbreviation: string;
  }[] = [];
  loading = false;
  addressValidated$: Observable<boolean>;

  constructor(private fb: FormBuilder, private dataService: DataService, private snackService: SnackService, private addressValidator: AddressUSPSValidatorService) {
    this.addressForm = this.fb.group({
      address: ['', Validators.required],
      address2: ['', Validators.required],
      state: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      primary: [false],
    });
  }

  ngOnInit(): void {
    this.states = this.dataService.statesWithabbreviation;
    this.initializeForm();
  }

  initializeForm(): void {
    if (this.address) {
      this.addressForm.patchValue(this.address);
      if (this.addressForm.get('postalCode').value === '') {
        this.addressForm.get('postalCode').setValue(this.address.zip);
      }
    }
  }

  saveAddress(): void {
    this.address = {
      ...this.addressForm.getRawValue(),
      id: this.address?.doc?.id ?? undefined,
    };
    this.toSaveAddress.emit(this.address);
  }

  deleteAddress(): void {
    this.address = {
      ...this.addressForm.getRawValue(),
      id: this.address?.doc?.id ?? undefined,
    };
    this.toDeleteAddress.emit(this.address);
  }

  async onValidateAddress() {
    this.loading = true;
    this.addressValidated$ = of(false);
    const address = this.addressValidator.getAddressValidatorObject(this.addressForm);
    const validatorResponse = await this.validateAddress(address);
    if (validatorResponse.error) {
      this.addressValidated$ = of(false);
      this.snackService.genericSnackBar(validatorResponse.error, ['error-snackbar'], 5000);
    } else {
      this.snackService.genericSnackBar('Address found! ', ['success-snackbar'], 5000);
      this.addressValidator.setDataFromValidatorAddress(validatorResponse, this.addressForm);
      this.addressValidated$ = of(true);
    }
    this.loading = false;
  }

  private async validateAddress(address) {
    const resp = await this.addressValidator.validateAddress(address).pipe(take(1)).toPromise();
    return this.addressValidator.parseXMLtoJSON(resp, address);
  }
}
