import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(private http: HttpClient) {}

  /**
   * Uploads files to the aws S3 via MS
   *
   * @param files
   * @param path
   * @returns
   */
  uploadFile(files: File[], path: string, overrideName?: string) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('path', path);
    if (overrideName) {
      formData.append('overrideName', overrideName);
    }
    const url = `${environment.welbyEndpoint}/api/v1/files/upload`;
    return this.http.post(`${url}`, formData);
  }
}
