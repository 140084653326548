import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Call } from '@twilio/voice-sdk';
import * as firebase from 'firebase';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ClinicalResolution } from 'src/app/models/clinical.model';
import { Patient, PatientContact } from 'src/app/models/patient.model';
import { User } from 'src/app/models/user.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { TwilioService } from 'src/app/services/communications/twilio.service';
import { DataService } from 'src/app/services/data.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientCommunicationType } from 'src/app/services/models/patient-communication.model';
import { PatientCommunicationService } from 'src/app/services/patient-communication/patient-communication.service';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SurveyDialogComponent } from '../survey-dialog/survey-dialog.component';

@Component({
  selector: 'app-call-patient-dialog',
  templateUrl: './call-patient-dialog.component.html',
  styleUrls: ['./call-patient-dialog.component.scss'],
})
export class CallPatientDialogComponent implements OnInit, AfterViewInit {
  existingPatient: boolean;
  patientContacts$: Observable<PatientContact[]>;
  patientId: string;
  callForm: FormGroup;
  phoneTypes: any[] = [];
  callInProgress = false;
  unsubscribeCallStatus = new Subject();
  provider: User;
  duration = 0;
  callConnected = false;
  currentCall: Call;
  durationInterval;
  durationFormatted = '00:00';
  contentVisible = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CallPatientDialogComponent>,
    private fsService: FirestoreService,
    private fb: FormBuilder,
    private dataService: DataService,
    private patientService: PatientService,
    private authService: NewAuthService,
    private snackBarService: SnackService,
    private communicationService: PatientCommunicationService,
    public twilio: TwilioService,
    public dialog: MatDialog,
    private utilService: UtilsService
  ) {
    this.initializeForm();
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.initializeTwilioClient();
    this.provider = {
      ...this.authService.user,
      twilio_line: this.utilService.normalizedTwilioLines(this.authService.user),
    };
    this.callForm.get('twilioNumber').setValue(this.provider.twilio_line[0]);
    this.phoneTypes = Object.values(this.dataService.phoneTypes);
    this.existingPatient = this.data.existingPatient;

    if (this.existingPatient && !this.data.isExternal) {
      this.patientId = this.data.id;
      this.patientContacts$ = this.fsService.colWithIds$<PatientContact[]>(`users/${this.patientId}/my_contacts`).pipe(
        takeUntil(this.dialogRef.afterClosed()),
        tap((data) => {
          if (data.length > 0) {
            const primaryContact = data.find((contact) => contact.isPrimary === true);
            if (primaryContact) {
              this.callForm.get('contactNumber').setValue(primaryContact);
            } else {
              this.callForm.get('contactNumber').setValue(data[0]);
            }
          }
        })
      );
    } else if (this.data.isExternal && this.data?.phone) {
      this.callForm.get('contactNumber').setValue(this.data?.phone);
    }
  }

  initializeTwilioClient(): void {
    this.twilio.getToken().then((token) => {
      this.twilio.initializeTwilio(token);
    });
  }

  initializeForm(): void {
    this.callForm = this.fb.group({
      contactNumber: ['', Validators.required],
      twilioNumber: ['', Validators.required],
      notes: ['', Validators.required],
      follow_up: [''],
      via: ['phone'],
    });
  }

  getIconFromType(contactType: string): string {
    let phoneType;
    if (this.phoneTypes && contactType) {
      phoneType = this.phoneTypes.find((pT) => pT.value === contactType);
    }
    return phoneType ? phoneType.icon : 'phone';
  }

  doCall(): void {
    if (this.callForm.get('contactNumber').value && this.authService.user && this.authService.user.phone && this.authService.user.twilio_line) {
      if (this.callForm.get('via').value === 'phone') {
        this.internalCall();
      } else {
        this.browserCall();
      }
    } else {
      this.snackBarService.genericSnackBar('Please verify sender and patient contact number', ['error-snackbar'], 5000);
    }
  }

  internalCall(): void {
    this.callInProgress = true;
    const senderLine = this.callForm.get('twilioNumber').value;
    const sender = { ...this.authService.user, twilio_line: senderLine };
    this.twilio.callPatient(this.getContactLine(this.callForm.get('contactNumber').value), sender, this.patientService?.currentPatientServiceID).subscribe(
      async (resp) => {
        if (!this.data.isExternal) {
          // Only enable call listener for patient chart calls
          this.enableCallListener(resp.sid);
          await this.saveInCommunicationHistory();
        }
        this.snackBarService.genericSnackBar('Calling in progress ...', ['success-snackbar'], 5000);
        this.removeBackDrop();
      },
      (error) => {
        this.snackBarService.genericSnackBar(`Internal Error: ${error}`, ['error-snackbar'], 5000);
        this.callInProgress = false;
        console.error(error);
      }
    );
  }

  browserCall(): void {
    const senderLine = this.callForm.get('twilioNumber').value;
    const sender = { ...this.authService.user, twilio_line: senderLine };
    this.twilio.makeBrowserCall({ To: `${this.getContactLine(this.callForm.get('contactNumber').value)?.replace(/-/g, '')}`, from: sender.twilio_line }).then((resp) => {
      this.currentCall = resp;
      this.setUpCallListeners(resp);
    });
  }

  getContactLine(contact: PatientContact | string): string {
    if (typeof contact === 'string') {
      return contact;
    } else {
      return contact?.contact_data;
    }
  }

  sendDigits(digits: string): void {
    if (this.currentCall) {
      this.currentCall.sendDigits(digits);
    } else {
      this.snackBarService.genericSnackBar('No active connection.', ['error-snackbar'], 5000);
      console.error('No active connection.');
    }
  }

  setUpCallListeners(call: Call): void {
    if (call) {
      call.on('disconnect', () => {
        if (this.duration > 0 && !this.data.isExternal) {
          this.saveInCommunicationHistory(this.duration);
          if (this.utilService.isSurveyEnabled(this.patientService.currentPatientService?.lastSurveyDate, this.patientService.currentPatientService?.client_responsible_id)) {
            this.showSnackBar();
          } else {
            this.snackBarService.genericSnackBar('Survey is not enabled for this patient. Last survey was sent within 30 days', ['warn-snackbar'], 8000);
          }
        }
        this.callInProgress = false;
        this.maximizeDialog();
        this.duration = 0;
        this.durationFormatted = '00:00';
        clearInterval(this.durationInterval);
      });
      call.on('accept', () => {
        this.callInProgress = true;
        this.minimizeDialog();
        this.snackBarService.genericSnackBar('Call in progress ...', ['success-snackbar'], 5000, '', 'right', 'top');
        this.durationInterval = setInterval(() => {
          this.duration = this.duration + 1;
          this.durationFormatted = this.displayTime(this.duration);
        }, 1000);
      });
    }
  }

  endCall(): void {
    this.currentCall?.disconnect();
    this.currentCall = null;
    this.dialogRef.updatePosition();
  }

  enableCallListener(sid: string) {
    this.fsService
      .doc(`users/${this.patientId}`)
      .valueChanges()
      .pipe(takeUntil(this.unsubscribeCallStatus))
      .subscribe((resp: Patient) => {
        if (resp) {
          if (resp.lastCall?.lastCallStatus === 'completed' && resp.lastCall?.lastCallSid === sid) {
            this.callInProgress = false;
            this.addBackDrop();
            if (this.utilService.isSurveyEnabled(this.patientService.currentPatientService?.lastSurveyDate, this.patientService.currentPatientService?.client_responsible_id)) {
              this.showSnackBar();
            } else {
              this.snackBarService.genericSnackBar('Survey is not enabled for this patient. Last survey was sent within 30 days', ['warn-snackbar'], 5000);
            }
            this.unsubscribeCallStatus.next();
          }
        }
      });
  }
  showSnackBar(): void {
    const snackBarRef = this.snackBarService.genericSnackBar(
      'A survey is available, do you want to send it to the patient?',
      ['success-snackbar'],
      20000,
      `Send Survey`,
      'right',
      'top'
    );
    snackBarRef.onAction().subscribe(() => {
      this.openSurveyDialog();
    });
  }
  saveNotes(): void {
    const resolution: ClinicalResolution = {
      assessor: this.authService.afAuth.auth.currentUser.uid,
      content: this.callForm.get('notes').value,
      follow_up: this.callForm.get('follow_up').value,
      date: firebase.firestore.Timestamp.fromDate(new Date()),
      associations: {
        uid: this.patientService?.currentPatientServiceID,
        client_id: this.patientService?.currentPatientService?.client_responsible_id,
      },
    };
    this.fsService.add(`clinical_assessments`, resolution).then((resp) => {
      this.snackBarService.genericSnackBar('Notes added', ['success-snackbar']);
      this.callForm.get('notes').reset();
      this.callForm.get('follow_up').reset();
    });
  }
  close(): void {
    this.dialogRef.close();
    this.currentCall?.disconnect();
    this.currentCall = null;
  }

  openSurveyDialog() {
    const dialogRef = this.dialog.open(SurveyDialogComponent, {
      data: {},
      width: '600px',
      height: 'auto',
      panelClass: 'welby-modal-scroll',
    });
  }

  displayTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes?.toString().padStart(2, '0')}:${remainingSeconds?.toString().padStart(2, '0')}`;
  }

  minimizeDialog() {
    this.contentVisible = false;
    this.dialogRef.updatePosition({ bottom: '0', right: '0' });
    this.removeBackDrop();
  }

  maximizeDialog() {
    this.contentVisible = true;
    this.dialogRef.updatePosition();
    this.addBackDrop();
  }

  removeBackDrop(): void {
    const backdrop = document.querySelector('.cdk-overlay-backdrop');
    backdrop.classList.add('cdk-overlay-backdrop-hide');
  }

  addBackDrop(): void {
    const backdrop = document.querySelector('.cdk-overlay-backdrop');
    backdrop.classList.remove('cdk-overlay-backdrop-hide');
  }

  private async saveInCommunicationHistory(seconds?: number) {
    const record = this.communicationService.buildCommunicationRecord(
      'Outbound call',
      seconds ? `Time: ${(seconds / 60).toFixed(2)} mins` : 'Internal call',
      this.patientService.currentPatientService,
      this.authService.user,
      PatientCommunicationType.CALL
    );
    try {
      await this.communicationService.saveCommunicationRecord(record, this.patientService.currentPatientService.user_id);
    } catch (error) {
      this.snackBarService.genericSnackBar('An error occurred while saving communication history', ['error-snackbar'], 5000);
      throw error;
    }
  }
}
