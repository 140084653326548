import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { EligibleMedicareCoverage } from 'src/app/models/eligible/eligible_medicare_coverage.model';
import { InsuranceCoverage, Patient } from 'src/app/models/patient.model';
import { DialogService } from 'src/app/services/dialog.service';
import { PatientDetailService } from 'src/app/services/patient-detail.service';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-eligibility-profile',
  templateUrl: './eligibility-profile.component.html',
  styleUrls: ['./eligibility-profile.component.scss'],
})
export class EligibilityProfileComponent implements OnInit {
  coverageColumns = ['member_id', 'payor', 'actions'];
  displayedColumns = ['number', 'type', 'remove'];
  eligibility: EligibleMedicareCoverage;
  isLoading = false;

  constructor(
    private http: HttpClient,
    public dialogService: DialogService,
    public patientService: PatientService,
    public patientDetailService: PatientDetailService,
    private datePipe: DatePipe,
    private db: AngularFirestore,
    private snackService: SnackService
  ) {}

  ngOnInit(): void {}

  checkMedicareEligbility(coverage: InsuranceCoverage, patient: Patient) {
    const body = {
      provider_npi: '1234567893',
      member_id: coverage.member_id,
      member_first_name: patient.firstName,
      member_last_name: patient.lastName,
      member_dob: patient?.dob ? this.datePipe.transform(patient?.dob.toDate(), 'yyyy-MM-dd') : '2020-01-01',
    };

    this.isLoading = true;
    // this.cdr.detectChanges();
    const url = 'https://us-central1-welby-dev.cloudfunctions.net/eligibleRest/eligible_coverage';
    this.http.post(url, body).subscribe(
      (val) => {
        this.eligibility = val;
        console.log('POST call successful value returned in body', val);
      },
      (response) => {
        console.log('Post request to eligible returned false', response);
      },
      () => {
        // console.log('The POST observable is now completed.');
        this.isLoading = false;
      }
    );
  }

  async deleteCoverage(coverage: InsuranceCoverage) {
    try {
      const uid = this.patientService.currentPatientServiceID;
      await this.db.collection('users').doc(uid).collection('my_health_coverage').doc(coverage.id).delete();
      this.snackService.genericSnackBar('Coverage deleted', ['success-snackbar'], 5000);
    } catch (error) {
      console.error(error);
      this.snackService.genericSnackBar('Error deleting coverage', ['error-snackbar'], 5000);
    }
  }
}
