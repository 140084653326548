import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ClinicalTimeDialogComponent } from '../dialogs/clinical-time-dialog.component';
import { ClinicalTime } from '../models/clinical.model';
import { TimeTrackingModalComponent } from '../shared/time-tracking-modal/time-tracking-modal.component';
import { TimeTrackingState } from '../shared/time-tracking-modal/time-tracking-states';
import { PatientService } from './patient.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PotentialWin, QuickWin } from './models/quick-wins.model';

@Injectable({
  providedIn: 'root',
})
export class PatientTimerService {
  currentTimerStateSubject: BehaviorSubject<any> = new BehaviorSubject(false);
  currentTimerStateObs$: Observable<boolean> = this.currentTimerStateSubject.asObservable();
  currentDisplayValuesSubject: BehaviorSubject<{ minutesSpent: number; secondsSpent: number; displayTime?: string }> = new BehaviorSubject({
    minutesSpent: 0,
    secondsSpent: 0,
    displayTime: '00:00',
  });
  currentDisplayValuesObs$: Observable<{ minutesSpent: number; secondsSpent: number; displayTime?: string }> = this.currentDisplayValuesSubject.asObservable();
  private maxTimeInSeconds = 900; // 15 minutes
  private maxTimeCounter = 1;
  private oneMinuteInSeconds = 60;
  private minutesSpent = 0;
  private secondsSpent = 0;
  private timeSpent = 0;
  private isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
  private initialDisplay = '00:00';
  private timerWorker: Worker;
  private timerSubject: Subject<number> = new Subject<number>();
  private clearTimer$: Subject<boolean> = new Subject<boolean>();
  private timerObs$: Observable<number> = this.timerSubject.asObservable();
  private reachedMaxTimeInSeconds = false;
  private isSecondComplete = 'tick';

  constructor(
    private fs: FirestoreService,
    private router: Router,
    private dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver,
    private patientService: PatientService
  ) {
    this.timerWorker = this.createWorker();
    this.timerWorker.onmessage = (event) => {
      if (event.data === this.isSecondComplete) {
        this.timerSubject.next(this.timeSpent + 1);
      }
    };
  }

  getOneMinuteInSeconds() {
    return this.oneMinuteInSeconds;
  }

  initializeTimer() {
    this.timerWorker.postMessage('start');
    this.clearTimer$ = new Subject<boolean>();
    this.timerObs$.pipe(takeUntil(this.clearTimer$)).subscribe((time) => {
      this.timeSpent = time;
      this.checkIfTimerIsCompleted(this.timeSpent);
    });
    this.currentTimerStateSubject.next(true);
  }

  pauseTimer() {
    this.currentTimerStateSubject.next(false);
    this.timerWorker.postMessage('stop');
  }

  stopTimer() {
    this.generalStopTimer();
    this.currentDisplayValuesSubject.next({ minutesSpent: 0, secondsSpent: 0, displayTime: '00:00' });
  }

  stopByMaxTime() {
    this.generalStopTimer();
    this.currentDisplayValuesSubject.next({ minutesSpent: 0, secondsSpent: 0, displayTime: 'Stopped' });
  }

  resumeTimer() {
    this.initializeTimer();
  }

  resetTimer() {
    this.clearTimer();
    this.initializeTimer();
  }

  clearTimer() {
    this.currentTimerStateSubject?.next(false);
    this.clearTimer$.next(true);
    this.clearTimer$.complete();
    this.timerWorker.postMessage('stop');
    this.timeSpent = 0;
    this.minutesSpent = 0;
    this.secondsSpent = 0;
    this.maxTimeCounter = 1;
    this.reachedMaxTimeInSeconds = false;
  }

  displayTime() {
    return `${this.minutesSpent?.toString().padStart(2, '0')}:${this.secondsSpent?.toString().padStart(2, '0')}`;
  }

  getMinutesSpent() {
    return this.minutesSpent;
  }

  getSecondsSpent() {
    return this.secondsSpent;
  }

  isTimmerCleared() {
    return this.displayTime() === this.initialDisplay;
  }

  openClinicalTime(category?: string) {
    const time = {
      category: category ?? 'rpms',
      minutes: this.getMinutesToSave(),
      seconds: this.getSecondsToSave(),
      timeStamp: new Date(),
      user_id: this.patientService.currentPatientService?.user_id,
      excluded: false,
      require_provider_intervention: false,
      device_troubleshooting: false,
    };
    this.openClinicalTimeDialog(time, true);
  }

  openClinicalTimeDialog(time?: ClinicalTime, isNew?: boolean, id?: string, needsClearTimer: boolean = true): void {
    const dialogRef = this.dialog.open(ClinicalTimeDialogComponent, {
      width: '500px',
      maxWidth: '100vw',
      panelClass: 'welby-modal-scroll',
      data: time
        ? {
            time: {
              minutes: time.minutes,
              mins: time.minutes,
              secs: time['seconds'],
              categories: time.categories ?? {},
              category: time.category,
              timeStamp: time.timeStamp,
              user_id: time.user_id,
              patient_id: time.patient_id,
              excluded: time.excluded,
              contact: time.contact,
            },
            isNew,
            id,
          }
        : { time: {}, isNew },
    });
    dialogRef.afterClosed().subscribe(() => {
      if (needsClearTimer) {
        this.clearTimer();
      }
    });
    this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });
  }

  openTimeTrackingModal(currentValue?: number): MatDialogRef<TimeTrackingModalComponent> {
    const modal = this.dialog.open(TimeTrackingModalComponent, {
      maxWidth: '100vw',
      data: {
        title: `Confirm that you are still tracking patient time`,
        body: 'Are you still tracking patient time?',
        patient: this.patientService.currentPatientService,
        timeSpent: currentValue ?? this.timeSpent,
      },
      disableClose: true,
    });
    this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        modal.updateSize('100vw', '100vh');
      }
    });
    return modal;
  }

  confirmIfUsersWantToLeave(isCounting: boolean): Observable<boolean> {
    if (this.reachedMaxTimeInSeconds || (!isCounting && this.isTimmerCleared())) {
      this.reachedMaxTimeInSeconds = false;
      return of(true);
    } else {
      const modal = this.openTimeTrackingModal();
      return this.setTimeTrackingAfterCloseListener(modal);
    }
  }

  setTimeTrackingAfterCloseListener(modal: MatDialogRef<TimeTrackingModalComponent>): Observable<boolean> {
    return modal.afterClosed().pipe(
      take(1),
      map((resp) => {
        if (resp === TimeTrackingState.KEEP_TRACKING) {
          this.resumeTimer();
          return false;
        } else {
          return true;
        }
      })
    );
  }

  messageBillingGoals(docId: string, patientId: string): Observable<PotentialWin[]> {
    return this.fs
      .col('quick_wins')
      .doc(docId)
      .get()
      .pipe(
        map((doc) => {
          if (doc.exists) {
            const data: any = doc.data();
            if (data && data.potential_wins) {
              const currentMonth = new Date().getMonth() + 1;
              return data.potential_wins.filter((patient: any) => {
                const createdOn = patient.createdOn?.toDate();
                return patient.patientId === patientId && (patient.measurement.type === 'rpm' || patient.measurement.type === 'ccm') && createdOn.getMonth() + 1 === currentMonth;
              });
            }
          }
          return null;
        })
      );
  }

  private generalStopTimer() {
    this.currentTimerStateSubject.next(false);
    this.clearTimer$.next(true);
    this.timerWorker.postMessage('stop');
  }

  private checkIfTimerIsCompleted(currentValue: number): void {
    this.minutesSpent = Math.floor(currentValue / this.oneMinuteInSeconds);
    this.secondsSpent = currentValue - this.minutesSpent * this.oneMinuteInSeconds;
    this.currentDisplayValuesSubject.next({ minutesSpent: this.minutesSpent, secondsSpent: this.secondsSpent, displayTime: this.displayTime() });
    if (currentValue % (this.maxTimeInSeconds * this.maxTimeCounter) === 0 && currentValue > 0) {
      this.stopByMaxTime();
      const modal = this.openTimeTrackingModal(currentValue);
      this.setTimeTrackingAfterCloseListener(modal).subscribe((flag) => {
        if (flag) {
          this.reachedMaxTimeInSeconds = true;
          this.router.navigateByUrl('clinical');
        }
        this.maxTimeCounter++;
      });
    }
  }

  private getMinutesToSave(): number {
    return this.timeSpent > this.oneMinuteInSeconds ? (this.timeSpent - (this.timeSpent % this.oneMinuteInSeconds)) / this.oneMinuteInSeconds : 0;
  }

  private getSecondsToSave(): number {
    return this.timeSpent % this.oneMinuteInSeconds;
  }

  private createWorker(): Worker {
    return new Worker(
      URL.createObjectURL(
        new Blob(
          [
            `
        let timer;

        const startTimer = () => {
          timer = setInterval(() => {
            postMessage('tick');
          }, 1000);
        };

        const stopTimer = () => {
          clearInterval(timer);
        };

        onmessage = (event) => {
          if (event.data === 'start') {
            startTimer();
          } else if (event.data === 'stop') {
            stopTimer();
          }
        };
      `,
          ],
          { type: 'text/javascript' }
        )
      )
    );
  }
}
