import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { DataService } from 'src/app/services/data.service';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { OrderVO } from 'src/app/services/device-ordering/value-objects/order.vo';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';
import { SnackService } from 'src/app/services/snack.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss'],
})
export class EditOrderComponent implements OnInit {
  orderForm: FormGroup;
  availableClients;
  constructor(
    public dataService: DataService,
    public dataModel: InitialDataModel,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditOrderComponent>,
    private auth: NewAuthService,
    private utilsService: UtilsService,
    private deviceService: DeviceOrderingService,
    private snackService: SnackService
  ) {}

  ngOnInit(): void {
    this.availableClients = Object.values(this.dataModel.clients);
    this.orderForm = this.fb.group({
      client: ['', Validators.required],
      device: [{ value: this.data?.device?.sku_display ?? '', disabled: true }, Validators.required],
      patient: [{ value: `${this.data?.patient?.firstName} ${this.data?.patient?.lastName}` ?? '', disabled: true }, Validators.required],
      orderDate: [{ value: this.utilsService.formatDate(this.data?.createdAt) ?? '', disabled: true }, Validators.required],
      requested: [{ value: this.dataService.getUserName(this.data?.userId) ?? '', disabled: true }, Validators.required],
    });
    this.orderForm.get('client').setValue(this.dataModel.clients[this.data.clientId]);
  }

  editOrder(): void {
    const updateClientObj = {
      orderId: this.data.orderId,
      clientId: this.orderForm.get('client').value.id,
    } as OrderVO;
    this.deviceService
      .updateOrderDevice(updateClientObj)
      .then((resp) => {
        this.snackService.genericSnackBar('Order updated successfully', ['success-snackbar'], 5000);
      })
      .catch((error) => {
        console.error(error);
        this.snackService.genericSnackBar('An error occurred while updating order', ['error-snackbar'], 5000);
      })
      .finally(() => this.dialogRef.close());
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
