import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InsuranceCoverage } from 'src/app/models/patient.model';
import { DataService } from 'src/app/services/data.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientService } from 'src/app/services/patient.service';
import { PayorService } from 'src/app/services/payor.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-insurance-dialog',
  templateUrl: './insurance-dialog.component.html',
  styles: ['.invisible {display: block; visibility: hidden; height: 0; width: 0;}'],
})
export class InsuranceDialogComponent implements OnInit {
  coverageForm: FormGroup;
  currentCoverages$: Observable<InsuranceCoverage[]>;
  currentCoverages = [];
  payors$: Observable<any[]>;
  payorIds: string[] = [];
  constructor(
    private fsService: FirestoreService,
    private patientService: PatientService,
    private db: AngularFirestore,
    private payorService: PayorService,
    public data: DataService,
    private fs: FormBuilder,
    public dialogRef: MatDialogRef<InsuranceDialogComponent>,
    private snackService: SnackService
  ) {}

  ngOnInit(): void {
    this.onInitForm();
    this.setPayorListener();
  }

  setPayorListener(): void {
    this.payors$ = this.coverageForm.get('payor').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      filter((text) => text && typeof text === 'string' && text.length > 2),
      tap(() => (this.payorIds = [])),
      distinctUntilChanged(),
      takeUntil(this.dialogRef.afterClosed()),
      switchMap((key) => this.payorService.getPayorsByName(key).pipe(take(1)))
    );

    this.coverageForm
      .get('payor')
      .valueChanges.pipe(filter((value) => typeof value === 'object'))
      .subscribe((value) => {
        this.payorIds = value?.payer_ids || [];
      });
  }

  displayFn(content: any): string {
    return content ? content?.payor_name : '';
  }

  get controls(): any {
    // a getter!
    return (this.coverageForm.get('coverages') as FormArray).controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSaveCoverage(): Promise<void> {
    try {
      const userRef = this.db.collection('users').doc(this.patientService.currentPatientServiceID).collection('my_health_coverage');
      const coverage = this.coverageForm.value;
      const docs = (await userRef.get().toPromise()).docs.map((doc) => doc.data());
      if (docs.find((doc) => doc.payor.payor_name === coverage.payor.payor_name && doc.member_id === coverage.member_id)) {
        this.snackService.genericSnackBar('Coverage Already Exists', ['error-snackbar'], 5000);
        return;
      }
      await userRef.add(coverage);
      this.snackService.genericSnackBar('Coverage Saved', ['success-snackbar'], 5000);
      this.dialogRef.close();
    } catch (error) {
      console.error(error);
    }
  }

  private observeAddresses(): void {
    this.currentCoverages$ = this.fsService.colWithIds$(`users/${this.patientService.currentPatientServiceID}/my_health_coverage`);
    this.currentCoverages$.subscribe((coverage: InsuranceCoverage[]) => {
      this.currentCoverages = coverage;
      this.onInitForm();
    });
  }

  private onInitForm(): void {
    const payor = '';
    const id = '';
    const coverageList = new FormArray([]);

    this.coverageForm = this.fs.group({
      payor: [payor, Validators.required],
      member_id: [id],
    });
  }
}
