<h2 mat-dialog-title>Insurance Coverage</h2>
<form [formGroup]="coverageForm">
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFill>
      <mat-form-field fxFlex>
        <mat-label>Search Payors</mat-label>
        <input type="text" matInput formControlName="payor" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of payors$ | async" [value]="option" [matTooltip]="option?.payor_name">
            {{ option?.payor_name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field fxFlex>
        <mat-label>Select Payor Ids</mat-label>
        <mat-select formControlName="member_id">
          <mat-option *ngFor="let id of payorIds" [value]="id">
            {{ id }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-raised-button color="primary" fxFlex="5" (click)="onSaveCoverage()">Add</button>
    </div>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end center">
    <button mat-button type="button" (click)="onNoClick()">Close</button>
  </div>
</form>
